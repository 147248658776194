<template>
  <div class="result-container" :class="{radio:status,search:search}">
    <div class="chart-list" :class="{checked:link.is_handle}" v-for="link in list">
      <div class="chart-top flex-center-between">
        <div class="mask-control flex-center">
          <div class="control-list flex-center link-edit" @click="edit(link)" title="重新编辑标题">
            <div class="iconfont icon-bianji"></div>
          </div>
          <div class="control-list flex-center link-delete" @click="edit(link,2)" title="删除网页">
            <div class="iconfont icon-cancel"></div>
          </div>
          <div class="control-list flex-center link-message" @click="edit(link,3)" title="查看网页信息">
            <div class="iconfont icon-tishi"></div>
          </div>
          <div class="control-list flex-center link-copy" v-clipboard:copy="link.link" v-clipboard:success="copySuccess" title="复制链接">
            <div class="iconfont icon-fuzhi"></div>
          </div>
        </div>
        <div class="select-link flex-center" :class="{active:link.is_handle}" @click="link.is_handle = !link.is_handle">
          <div class="iconfont icon-check1"></div>
        </div>
      </div>
      <a rel="noopener noreferrer" target="_blank" :href="link.link" @click="setLinkClickNum(link.id)">
        <div class="list-container flex">
          <div class="list-img">
<!--            <img :src="link.image || ''" :class="{done:link.image_success}" @load="imgLoadSuccess(link)">-->
            <img :src="link.image || ''" class="done">
          </div>
          <div class="list-content flex-col">
            <div class="title-wrapper flex-center-between">
              <div class="title nowrap" :title="link.title || link.link" v-html="link.title || link.link"></div>
              <div class="title-category nowrap">{{link.group_name || '未分类'}}</div>
            </div>
            <div class="text nowrap-2" :title="link.info || '未编辑内容'" v-html="link.info || '未编辑内容'"></div>
            <div class="list-footer flex-center-between">
              <div class="link" v-if="link.link_search" v-html="link.link_search"></div>
              <div class="data-container flex" v-else>
                <div class="hot flex-center" :title="link.click > 0 ? '网址的点击总量' : '未有点击量'">
                  <i class="iconfont icon-hot"></i>
                  <div class="hot-text">{{link.click || '-'}} 次点击</div>
                </div>
                <div class="cellction flex-center" :title="link.collection > 0 ? '其他用户收藏量' : '未有其他用户收藏'">
                  <i class="iconfont icon-shoucang"></i>
                  <div class="cellction-text">{{link.collection || '-'}} 次收藏</div>
                </div>
              </div>
              <div class="time">{{link.time_tran}}</div>
            </div>
          </div>
        </div>
      </a>
      <div class="share-radio flex-center" :class="{'share-active':link.is_share}" @click="link.is_share = !link.is_share"  title="正在设置分享状态">
        <div class="state-container"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChartMode",
  props: {
    status: Boolean,
    list: Array,
    shareMulti:Number,
    search:Boolean
  },
  watch:{
    shareMulti(type){
      if(type > 0){
        this.list.map((item)=>{
          if(type === 1){
            item.is_share = true;
          }else if(type === 2){
            item.is_share = false;
          }else{
            item.is_share = !item.is_share;
          }
        });
        this.$emit('initShareMulti',0);
      }
    }
  },
  methods:{
    edit(info,type = 1){
      this.$emit('edit',info,type);
    }
  }
}
</script>

<style scoped>
.share-radio.share-active .state-container {
  background-color: #3dca8d;
}
.share-radio.share-active .state-container:after {
  transform: translate3d(26px,0,0);
}
.title-wrapper .title-category{
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 5px;
  background-color: #e3ebfa;
  max-width: 68px;
}
.result-container{
  display: grid;
  grid-gap:20px;
  grid-template-columns:repeat(auto-fill,minmax(530px,1fr));
  grid-template-rows:auto;
}
.chart-list{
  position: relative;
  border-radius: 8px;
  box-shadow: 0 0 20px rgb(193 201 255 / 50%);
  background-color: #fff;
  transition: all .25s cubic-bezier(.215,.61,.355,1);
  z-index: 1;
}
.chart-list a{
  overflow: hidden;
  border-radius: 8px;
}
.chart-list a:after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: calc( 100% - 8px );
  width: calc( 100% - 8px );
  border-radius: 8px;
  border: solid 4px transparent;
  z-index: 1;
}
.chart-list:hover a:after,
.chart-list.checked a:after{
  border-color: #0023ff;
}
.chart-list:hover .data-container *{
  color: #282828 !important;
}
.chart-list .list-img{
  flex:0 0 150px;
  height: 113px;
  overflow: hidden;
}
.chart-list .list-img img{
  width: 100%;
  min-height: 100%;
}
.chart-list .list-container{
  align-items: stretch;
  -webkit-align-items: stretch;
}
.chart-list .list-content{
  flex: 1;
  padding:12px 15px;
  overflow: hidden;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}
.chart-list .list-content .title{
  font-weight: bold;
  padding-right: 15px;
  flex: 1;
}
.result-container.search .list-content .text{
  overflow-y: auto;
}
.chart-list .list-content .text{
  position: relative;
  z-index: 2;
  color: #888;
  font-size: 12px;
  height: 35px;
}
.chart-list .list-content .cellction *{
  color: #888;
}
.chart-list .list-content .cellction .iconfont{
  margin-right: 3px;
}
.chart-list .list-content .cellction .cellction-text{
  font-size: 12px;
}
.chart-list .list-content .time{
  text-align: right;
  flex:0 0 105px;
  font-size: 12px;
  color: #888;
}
.chart-list:hover .link{
  color: #2643f7;
}
.chart-list:hover .list-content .text{
  color: #282828;
}
.chart-list .list-content a{
  flex: 1;
  color: #2643f7;
  padding-right: 10px;
}
.result-container .chart-list:nth-child(5n+1) .list-img{
  background-color: #a4e9fc;
}
.result-container .chart-list:nth-child(5n+2) .list-img{
  background-color: #D7E2F6;
}
.result-container .chart-list:nth-child(5n+3) .list-img{
  background-color: #F3F4F9;
}
.result-container .chart-list:nth-child(5n+4) .list-img{
  background-color: #e8f3c4;
}
.result-container .chart-list:nth-child(5n+5) .list-img{
  background-color: #F2F5F8;
}
.chart-top{
  position: absolute;
  width: calc( 100% - 150px );
  z-index: 99;
  top: -26px;
  left: 150px;
  padding: 8px 12px;
}
.chart-list .select-link{
  cursor: pointer;
  background-color: #fff;
  width: 40px;
  height: 26px;
  border-radius: 35px;
  text-align: center;
  box-shadow: 4px 12px 40px 6px rgb(0 0 0 / 9%);
  opacity: 0;
  visibility:hidden;
  transition:transform .4s cubic-bezier(.215,.61,.355,1);
  transform: translateX(5px);
}
.chart-list .select-link.active{
  background-color: #2643f7;
}
.chart-list .select-link .iconfont{
  font-size: 20px;
  transition:color .15s ease;
}
.chart-list .select-link.active:hover .iconfont,
.chart-list .select-link.active .iconfont{
  color: #fff;
}
.chart-list .select-link:hover .iconfont{
  color: #2643f7;
}
.chart-list .mask-control{
  position: relative;
  top: 0;
  background-color: #fff;
  padding: 0 10px;
  border-radius: 35px;
  box-shadow: 4px 12px 40px 6px rgb(0 0 0 / 9%);
  transform: translateX(-5px);
}
.chart-list .mask-control .control-list{
  padding: 3px 5px;
}
.chart-list:hover .mask-control,
.chart-list:hover .select-link,
.chart-list:hover .list-img:after,
.chart-list .select-link.active{
  opacity: 1;
  visibility:visible;
  transform: translateX(0);
}
.result-container.radio .chart-list:hover{

}
.result-container.radio .chart-list:hover .list-img:after{
  visibility: hidden;
  opacity: 0;
}
.result-container.radio .chart-top{
  display: none;
}
.result-container.radio .chart-list:hover .list-content .text,
.result-container.radio .list-content *{
  color: #888;
}
.list-footer .link{
  width: 65%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
</style>