import axios from 'axios'
import store from '../store';
import Vue from 'vue';
import func from '@/api/global_func'
Vue.use(func);

export function res(options) {
    return new Promise((resolve, reject) => {
        let excludeArr = [
            '/api/links/getWebInfo',
            '/api/links/ping',
            '/api/user/setUserViewType',
            '/api/user/setUserGroupSort'
        ];
        let loadingStatus = true;
        excludeArr.map((item)=>{
            if(options.url.indexOf(item) === 0){
                loadingStatus = false;
                return false;
            }
        })
        store.commit('set_loading', loadingStatus);
        // let token = window.localStorage.getItem('token') || '51f0d95a31ecf19df90e6451a8905de6';
        let token = window.localStorage.getItem('token');
        if(!token){
            window.location.href = 'https://www.if2c.cn';
        }
        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'AccessToken': token
            }
        });
        instance(options)
            .then(response => {
                store.commit('set_loading', false);
                if (response.data.code === 200) {
                    if (response.data.status === "SUCCESS") { //只过滤返回服务器端状态为200的数据
                        resolve(response.data);
                    } else if (response.data.status === 'UNTOKEN' || response.data.status === 'UNLOGIN') {
                        //登录过期
                        window.localStorage.removeItem('token');
                        window.location.href = 'https://www.if2c.cn';
                        // func.toast('登录过期',2);
                    } else{
                        //接口错误
                        func.toast(response.data.message,2);
                    }
                } else {
                    func.toast(response.data.message,2);
                }
            })
            .catch(err => {
                //接口错误
                store.commit('set_loading', false);
                reject(err);
            })
    });
}