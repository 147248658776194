<template>
  <div>
    <div class="content-top flex-center-between">
      <div v-show="false">
        {{getList}}
      </div>
      <div class="title flex-center">
        <div class="text">{{choiceGroupName}}</div>
        <div class="count">{{total || 0}}</div>
      </div>
      <div class="content-control flex-center-between">
        
        <div class="mask-share-control" :class="{'share-active':showShareSta}">
          <div class="share-all flex-center-between">
            <div class="share-all-switch" @click.stop="setShareMulti(1)">全部分享</div>
            <div class="share-all-cancel" @click.stop="setShareMulti(2)">全部取消</div>
            <div class="share-all-invert" @click.stop="setShareMulti(3)">反选</div>
            <div class="share-all-invert" @click.stop="setShareMulti(4)">取消修改</div>
          </div>
        </div>
        <div class="share-switch flex-center" :class="{'share-active':showShareSta}" title="设置网页链接分享状态，分享后其他用户可以通过你的主页地址进行访问">
          <div class="share-switch-wrapper" @click.stop="setShareSta">
            <div class="title flex-center set-radio">
              <div class="text">设置分享</div>
              <div class="iconfont icon-fenxiang"></div>
            </div>
            <div class="title flex-center">
              <div class="text">完成修改</div>
            </div>
          </div>
        </div>
        <div class="go-all">
          
        </div>
        <div class="show-mode" :class="classObject" title="点击可选择多种显示模式">
          <div class="show-mode-container" @click.stop="setShowMode">
            <div class="title flex-center">
              <div class="text">显示模式</div>
              <div class="icon-swiper">
                <div class="icon-wrapper">
                  <div class="iconfont" v-for="mode in showModeList" :class="mode.icon"></div>
                </div>
              </div>

            </div>
            <div class="show-mode-wrapper">
              <div class="show-list flex" v-for="(mode,key) in showModeList" @click="choiceMode(key)">
                <div class="iconfont" :class="mode.icon"></div>
                <div class="text">{{mode.name}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="share-rank" :class="{active:showSortSta}" title="点击可排序">
          <div class="share-rank-container">
            <div class="title flex-center" @click.stop="setSortSta">
              <div class="text">排序</div>
              <i class="iconfont icon-transfer"></i>
            </div>
            <div class="share-rank-wrapper">
              <div class="rank-list" v-for="(name,key) in sortTypeArr" @click="setSort(key + 1)">{{name}}</div>
            </div>
          </div>
        </div>
        <div class="share-import" title="导入浏览器收藏夹，你需要将你的浏览器收藏夹导出形成文件" v-if="false">
          <input type="file" name="" accept="text/html" id="import" style="display: none;">
          <label for="import">
            <div class="title flex-center">
              <div class="text">导入</div>
              <i class="iconfont icon-wenjianjia"></i>
            </div>
          </label>
        </div>
        <div class="show-more flex-center" @click="showNotGroup" :class="{active:groupId === -1}">
          <div class="title flex-center" >
              <div class="text">仅显示未分类</div>
              <div class="iconfont icon-tishi"></div>
          </div>
        </div>
      </div>
    </div>
    <div id="web_content">
      <cardMode v-if="nowMode === 0" :status="showShareSta" :list="list" :shareMulti="shareMulti" @edit="edit" @initShareMulti="initShareMulti"></cardMode>
      <chartMode v-else-if="nowMode === 1" :status="showShareSta" :list="list" :shareMulti="shareMulti" @edit="edit" @initShareMulti="initShareMulti"></chartMode>
      <imageMode v-else-if="nowMode === 2" :status="showShareSta" :list="list" :shareMulti="shareMulti" @edit="edit" @initShareMulti="initShareMulti"></imageMode>
      <miniMode v-else-if="nowMode === 3" :status="showShareSta" :list="list" :shareMulti="shareMulti" @edit="edit" @initShareMulti="initShareMulti"></miniMode>
    </div>
  </div>
</template>

<script>
import cardMode from '../components/mode/CardMode'
import chartMode from '../components/mode/ChartMode'
import imageMode from '../components/mode/ImageMode'
import miniMode from '../components/mode/MiniMode'

import store from '../store/index'
import {getLinkList,setLinkShare,getGroupList,setUserViewType,setUserGroupSort} from "@/api/apis";
export default {
  name: "All",
  components: {
    cardMode,
    chartMode,
    imageMode,
    miniMode,
  },
  data(){
    return{
      showModeSta:false,
      showShareSta:false,
      showSortSta:false,
      nowMode:0,
      shareMulti:0,
      showModeList:[
        {name:'卡片模式','icon':'icon-yingyongAPP',alias:'card'},
        {name:'列表模式','icon':'icon-liebiao',alias:'chart'},
        {name:'照片模式','icon':'icon-tupian',alias:'image'},
        {name:'极简模式','icon':'icon-linggan',alias:'mini'},
      ],
      classObject:{
        active:false,
        card:false,
        chart:false,
        image:false,
        mini:false,
      },
      list:[1],
      sortTypeArr:[
        '根据时间',
        '点击量',
        '收藏量',
      ],
      sortType:1,
      nowPage:1,
      nowLimit:100,
      is_load:false,
      is_end:false,
      total:0
    }
  },
  watch:{
    showModeSta(i){
      this.classObject.active = i;
    },
    nowMode(i){
      this.classObject.card = false;
      this.classObject.chart = false;
      this.classObject.image = false;
      this.classObject.mini = false;
      switch (i){
        case 0:
          this.classObject.card = true;
          break;
        case 1:
          this.classObject.chart = true;
          break;
        case 2:
          this.classObject.image = true;
          break;
        case 3:
          this.classObject.mini = true;
          break;
          default:
            break;
      }
    },
    groupId(info){
      this.getLinkListInit();
    },
    userInfo(info){
      this.nowMode = info.view_type - 1;
    },
  },
  computed:{
    getList(){
      if(store.state.getList > 0){
        this.getLinkListInit();
      }
      return store.state.getList;
    },
    groupId(){
      return store.state.searchGroupId;
    },
    allNum(){
      return store.state.allLinkNum;
    },
    userInfo(){
      return store.state.userInfo;
    },
    choiceGroupName(){
      return store.state.choiceGroupName;
    }
  },
  methods:{
    setShowMode(){
      if(!this.showModeSta){
        this.closeAllActive();
      }
      this.showModeSta = !this.showModeSta
    },
    choiceMode(key){
      if(this.showModeList[key]){
        this.nowMode = key;
        setUserViewType(key);
      }
    },
    closeAllActive(){
      this.showModeSta = false;
      this.showShareSta = false;
      this.showSortSta = false;
    },
    getLinkListFn(){
      getLinkList(store.state.searchGroupId,this.sortType,this.nowPage,this.nowLimit).then((info)=>{
        this.is_load = false;
        this.total = info.data.total;
        if(info.data.data.length < this.nowLimit){
          //已经最后一页了
          this.is_end = true;
        }
        if(this.nowPage > 1){
          this.list.push(...info.data.data);
        }else{
            this.list = info.data.data;
        }
        store.commit('set_link_list',this.list);
      });
    },
    getLinkListInit(){
      this.sortType = 0;
      this.nowPage = 1;
      this.is_end = false;
      this.getLinkListFn();
    },
    setShareMulti(type){
      if(type === 4){
        this.showShareSta = false;
        return false;
      }
      this.shareMulti = type;
    },
    initShareMulti(){
      this.shareMulti = 0;
    },
    setShareSta(){
      if(this.showShareSta){
        let changeArr = [];
        this.list.map((item)=>{
          if(item.is_share !== item.old_is_share){
            changeArr.push({
              id:item.id,
              sta:item.is_share ? 1 : 0
            });
            item.old_is_share = item.is_share;
          }
        });
        if(changeArr.length > 0){
          setLinkShare(changeArr);
        }
      }else{
        this.closeAllActive();
      }
      this.showShareSta = !this.showShareSta;
    },
    edit(info,type){
      let tmp_data = JSON.parse(JSON.stringify(info));
      tmp_data.handle_type = type;
      tmp_data.ping = '';
      store.commit('set_edit_data', tmp_data);
    },
    setSortSta(){
      if(!this.showSortSta){
        this.closeAllActive();
      }
      this.showSortSta = !this.showSortSta
    },
    setSort(info){
      this.sortType = info;
      this.nowPage = 1;
      setUserGroupSort(info,2);
      this.getLinkListFn();
    },
    scroll(){
      let ele = document.getElementById('content');
      ele.onscroll = ()=>{
        if(this.$route.path === '/main'){
          if(ele.scrollHeight - (ele.clientHeight + ele.scrollTop) <= 250){
            if(!this.is_load && !this.is_end){
              this.is_load = true;
              this.nowPage++;
              this.getLinkListFn();
            }
          }
        }
      }
    },
    showNotGroup(){
      if(store.state.searchGroupId === -1){
        store.commit('set_group_id',0);
        store.commit('set_choice_group','全部收藏');
      }else{
        store.commit('set_group_id',-1);
        store.commit('set_choice_group','未分组');
      }
    }
  },
  created() {
    this.shareMulti = 0;
    this.getLinkListInit();
    this.getGroup();
  },
  mounted(){
    this.scroll();
    let options = {
	    root: document.getElementById('content'),
	    threshold: [0, 0.5, 1],
	}
	setTimeout(()=>{
 var io = new IntersectionObserver((entries)=>{
	     entries.forEach((item) => { // 遍历entries数组
	        if(item.isIntersecting){ // 当前元素可见
	        	// console.log('可见');
	            // item.target.src = item.target.dataset.src  // 替换src
	            // io.unobserve(item.target)  // 停止观察当前元素 避免不可见时候再次调用callback函数
	        }else{
	        	// console.log('不可见');
	        }
	    })
	},options);
	console.log(document.querySelectorAll('[data-src]').length);
document.querySelectorAll('[data-src]').forEach((item)=>{
    io.observe(item)
})
	},1000);
   
  }
}
</script>

<style scoped>
.mask-share-control.share-active{
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}
.share-switch.share-active{
  background-color: #1d1d1f;
}
.share-switch.share-active .share-switch-wrapper{
  transform: translate3d(-118px,0,0);
}
.share-switch.share-active .title *{
  color: #FFFFFF;
}
</style>