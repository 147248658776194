import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import All from '../views/All.vue'
import Recycle from '../views/Recycle.vue'
import Draft from '../views/Draft.vue'
import Search from '../views/Search.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component:Home,
    children:[
      {
        path: 'main',
        name: 'main',
        component: All,
      },
      {
        path: 'recycle',
        name: 'recycle',
        component: Recycle,
      },
      {
        path: 'draft',
        name: 'draft',
        component: Draft,
      },
      {
        path: 'search',
        name: 'search',
        component: Search,
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
