// param为传入参数
import store from "@/store";

// xx.js文件
var tools = {}
tools.toast = function (msg, type = 1) {
    store.commit('set_toast',true);
    store.commit('set_toast_msg',msg);
    store.commit('set_toast_type',type);
    setTimeout(()=>{
        store.commit('set_toast',false);
    },2000);
} // 还可以在这个文件里面添加多个函数
tools.install = function (Vue, options) {
    Vue.prototype.toast = (msg,type) => {
        toast(msg,type)
    }
}
export default tools