<template>
  <div>
    <div class="content-top flex-center-between">
      <div class="title flex-center">
        <div class="text">回收站</div>
        <div class="samll-title flex-center">
          <div class="r-text">已有{{recycleNum}}条链接待处理，</div>
          <div>回收站里的网页将保存15天，15天后将自动清除。</div>
        </div>
      </div>
      <div class="content-control flex-center-between">
        <div class="delete-back" @click="revoke">
          <div class="title flex-center">
            <div class="text">撤销</div>
            <i class="iconfont icon-chexiao"></i>
          </div>
        </div>
        <div class="delete-clear" @click="clear(1)" v-if="false">
          <div class="title flex-center">
            <div class="text">清除</div>
            <i class="iconfont icon-shanchu"></i>
          </div>
        </div>
        <div class="delete-all" @click="clear(2)">
          <div class="title flex-center">
            <div class="text">全部清空</div>
            <i class="iconfont icon-shanchu"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="web-container">
      <div class="web-list" v-for="link in list" :class="{checked:link.is_handle}">
      <div class="list-container">
        <a class="list-wrapper" rel="noopener noreferrer" target="_blank" :href="link.link">
          <div class="link-head">
            <img :src="link.image || ''" data-src="">
          </div>
          <div class="link-content flex-center-between">
            <div class="info">
              <div class="text nowrap">{{link.title}}</div>
              <div class="category nowrap" >{{link.group_name || '未分类'}}</div>
            </div>
            <div class="ico">
              <img :src="link.ico" @error="icoLoadError(link)">
            </div>
          </div>
          <div class="web-footer ">
            <div class="message nowrap-2" :title="link.info" v-html="link.info"></div>
            <div class="flex-center-between">
              <div class="data-container flex">
                <div class="hot flex-center" :title="link.click > 0 ? '网址的点击总量' : '未有点击量'">
                  <i class="iconfont icon-hot"></i>
                  <div class="hot-text">{{link.click || '-'}} 次点击</div>
                </div>
                <div class="cellction flex-center" :title="link.collection > 0 ? '其他用户收藏量' : '未有其他用户收藏'">
                  <i class="iconfont icon-shoucang"></i>
                  <div class="cellction-text">{{link.collection || '-'}} 次收藏</div>
                </div>
              </div>
              <div class="date nowrap">{{link.time_tran}}</div>
            </div>
          </div>
        </a>
        <div class="select-link flex-center" :class="{active:link.is_handle}" @click="link.is_handle = !link.is_handle">
          <div class="iconfont icon-check1"></div>
        </div>
      </div>
    </div>
      <div class="recyle-nothing flex-center" v-if="list.length < 1">
        <div class="r-nothing-container flex-col-center">
          <div class="nothing-img"></div>
          <p class="text">回收站很干净噢~</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getRecycleList,revokeLink,clearLink} from '@/api/apis';
import store from '@/store/index';
export default {
  name: "Recycle",
  data(){
    return{
      list:[],
      choiceLinkArr:[],
      nowPage:1,
      nowLimit:50,
      is_load:false,
      is_end:false,
    }
  },
  watch:{
    recycleList(info){
      if(info === 'new'){
        this.initNum();
      }
    }
  },
  computed:{
    recycleNum(){
      return store.state.recycleNum;
    },
    recycleList(){
      return store.state.recycleList;
    }
  },
  methods:{
    getRecycleList(){
      getRecycleList(this.nowPage,this.nowLimit).then((d)=>{
        this.is_load = false;
        if(d.data.data.length < this.nowLimit){
          //已经最后一页了
          this.is_end = true;
        }
        if(this.nowPage > 1){
          this.list.push(...d.data.data);
        }else{
          this.list = d.data.data;
        }
        store.commit('set_recycle_list',this.list);
      });
    },
    getChoiceLinks(){
      this.choiceLinkArr = [];
      this.list.some((item)=>{
        if(item.is_handle){
          this.choiceLinkArr.push(JSON.parse(JSON.stringify(item)));
        }
      });
    },
    revoke(){
      this.getChoiceLinks();
      if(this.choiceLinkArr.length < 1){
        this.toast('请至少选中一个网页');
        return false;
      }
      this.$confirm('撤销链接','正在撤销选择的链接，一共选择了'+this.choiceLinkArr.length+'个链接',()=>{
        let ids = [];
        this.choiceLinkArr.map((item)=>{
          ids.push(item.id);
        });
        revokeLink(ids).then(()=>{
          this.toast('撤销成功');
          this.initNum();
        });
      });
    },
    clear(type){
      let msg = '正在清除所有的回收站链接';
      if(type === 1){
        this.getChoiceLinks();
        if(this.choiceLinkArr.length < 1){
          this.toast('请至少选中一个网页');
          return false;
        }
        msg = '正在清除选择的链接，一共选择了'+this.choiceLinkArr.length+'个链接';
      }
      this.$confirm('清除链接',msg,()=>{
        let ids = [];
        this.choiceLinkArr.map((item)=>{
          ids.push(item.id);
        });
        clearLink(ids).then(()=>{
          this.toast('清除成功');
          this.clearSta = false;
          this.initNum();
        });
      });
    },
    initNum(){
      this.nowPage = 1;
      this.getRecycleList();
      this.getGroup();
      this.getUserAllLinksNum();
    },
    scroll(){
      let ele = document.getElementById('content');
      ele.onscroll = ()=>{
        if(this.$route.path === '/recycle'){
          if(ele.scrollHeight - (ele.clientHeight + ele.scrollTop) <= 250){
            if(!this.is_load && !this.is_end){
              this.is_load = true;
              this.nowPage++;
              this.getRecycleList();
            }
          }
        }
      }
    }
  },
  created() {
    this.nowPage = 1;
    this.getRecycleList();
  },
  mounted(){
    this.scroll();
  }
}
</script>

<style scoped>
  .nothing{
    display: none;
  }
  .recyle-nothing{
    position: absolute;
    top: 70px;
    left: 0;
    height: calc( 100% - 140px );
    width: 100%;
    animation: nothing-show 0.4s ease-in-out forwards;
  }
  .r-nothing-container{
    animation: nothing-scale 0.4s ease-in-out forwards; 
  }
  .recyle-nothing .nothing-img{
    background-repeat: no-repeat;
    height: 120px;
    width: 120px;
    background-image: url(../assets/svg/trash.svg);
    background-size: cover;
    margin-bottom: 20px;
  }
  .recyle-nothing .text{
    color: #dedede;
    font-size: 20px;
  }
</style>