<template>
  <div class="draft-container" :class="{radio:status}">
    <div class="draft-list" :class="{checked:link.is_handle}" v-for="link in list">
      <div class="list-container flex">
        <div class="list-img flex-center">
          <img :src="link.ico" @error="icoLoadError(link)">
        </div>
        <div class="list-content flex-col">
          <div class="title-wrapper flex-center-between">
            <div class="title nowrap" :title="link.title || link.link">{{link.title || link.link}}</div>
            <div class="draft-check nowrap flex-center" :class="{active:link.is_handle}" @click="link.is_handle = !link.is_handle">
              <i class="iconfont icon-check1"></i>
            </div>
          </div>
          <div class="list-footer flex-center-between">
            <a class="nowrap" rel="noopener noreferrer" target="_blank"  @click="setLinkClickNum(link.id)" :href="link.link" title="点击跳转">{{link.link}}</a>
            <div class="time">{{link.time_tran}}</div>
          </div>
        </div>
        <div class="mini-control">
          <div class="mask-control flex-center">
            <div class="control-list flex-center link-edit" @click="edit(link)" title="重新编辑标题">
              <div class="iconfont icon-bianji"></div>
            </div>
            <div class="control-list flex-center link-delete" @click="edit(link,2)" title="删除网页">
              <div class="iconfont icon-cancel"></div>
            </div>
            <div class="control-list flex-center link-message" @click="edit(link,3)" title="查看网页信息">
              <div class="iconfont icon-tishi"></div>
            </div>
            <div class="control-list flex-center link-copy" v-clipboard:copy="link.link" v-clipboard:success="copySuccess" title="复制链接">
              <div class="iconfont icon-fuzhi"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="share-radio flex-center" :class="{'share-active':link.is_share}" @click="link.is_share = !link.is_share" title="正在设置分享状态">
        <div class="state-container"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MimiMode",
  props: {
    status: Boolean,
    list: Array,
    shareMulti:Number
  },
  watch:{
    shareMulti(type){
      if(type > 0){
        this.list.map((item)=>{
          if(type === 1){
            item.is_share = true;
          }else if(type === 2){
            item.is_share = false;
          }else{
            item.is_share = !item.is_share;
          }
        });
        this.$emit('initShareMulti',0);
      }
    }
  },
  methods:{
    edit(info,type = 1){
      this.$emit('edit',info,type);
    }
  }
}
</script>

<style scoped>
.share-radio.share-active .state-container {
  background-color: #3dca8d;
}
.share-radio.share-active .state-container:after {
  transform: translate3d(26px,0,0);
}
.draft-title,
.title-wrapper .draft-check{
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 5px;
  background-color: #e3ebfa;
}
.draft-check{
  cursor: pointer;
  transition: all .25s cubic-bezier(.215,.61,.355,1);
  height: 26px;
  width: 32px;
}
.draft-check .iconfont{
  color: #888;
}
.draft-check:hover .iconfont{
  color: #2643f7;
}
.draft-check.active{
  background-color: #2643f7;
}
.draft-check:hover .iconfont{
  color: #2643f7;
}
.draft-check.active .iconfont,
.draft-check.active:hover .iconfont{
  color: #fff;
}
.draft-container{
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(400px,1fr));
  grid-gap: 20px;
}
.draft-list{
  position: relative;
  border-radius: 8px;
  box-shadow: 0 0 20px rgb(193 201 255 / 50%);
  background-color: #fff;
  transition: all .25s cubic-bezier(.215,.61,.355,1);
  border: solid 4px transparent;
}
.draft-list:hover,
.draft-list.checked{
  border-color: #0023ff;
}
.draft-list .mini-control{
  position: absolute;
  top: -15px;
  left: 47px;
}
.draft-list .mask-control{
  position: relative;
  top: 0;
  right: 0;
  transform: translateX(-5px);
}
.draft-list:hover .mask-control{
  visibility: visible;
  opacity: 1;
}
.draft-list .list-img{
  flex:0 0 50px;
  overflow: hidden;
}
.draft-list .list-img img{
  width: 30px;
  height: 30px;
  border-radius: 8px;
}
.draft-list .list-container{
  margin: -4px;
  align-items: stretch;
  -webkit-align-items: stretch;
}
.draft-list .list-content{
  flex: 1;
  padding:12px 15px 12px 5px;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  overflow: hidden;
}
.draft-list .list-content .title{
  font-weight: bold;
  padding-right: 10px;
}
.draft-list .list-content .text{
  color: #888;
  font-size: 12px;
  height: 35px;
  overflow: hidden;
}
.draft-list .list-content .time{
  text-align: right;
  font-size: 12px;
}
.draft-list .list-content a{
  flex: 1;
  color: #2643f7;
  padding-right: 10px;
}
.draft-list .list-footer{
  align-items: baseline;
  -webkit-align-items: baseline;
}
.draft-container.radio .draft-list .mini-control{
  display: none;
}
.draft-container.radio .draft-list .title,
.draft-container.radio .draft-list a,
.draft-container.radio .draft-list .time{
  color:#888;
}
</style>