<template>
  <div>
    <div class="trans-mask services-trans flex-center" :class="{show:sta}">
      <div class="mask-container">
        <div class="services-logo flex-col-center">
          <img src="../assets/svg/if2c.svg">
          <div class="text">《服务条款》</div>
        </div>
        <div class="services-list-container">
          <div class="services-list">
            <div class="title">前言</div>
            <div class="text">IF2C.CN提供的网络收藏夹服务是轻量级应用，使用的用户信息均是微信用户API提供的信息，使用者（也称"您"）在访问或使用本网站及其服务时，IF2C.CN提供的服务仅为您个人非商业性质的使用，请放心使用。</div>
          </div>
          <div class="services-list">
            <div class="title">服务内容</div>
            <div class="text">IF2C.CN提供的网络收藏夹服务，每位微信用户可以保存不超过300个网址信息，除非您与IF2C.CN另有约定。</div>
          </div>
          <div class="services-list">
            <div class="text">您收藏的网页地址，请尽可能是为了完成日常工作或其他积极向上的活动，如：一些平时公司及个人可能会用到网页或网址，您可以将您的主页地址（每位微信用户都有专属的主页地址）发送给同事或朋友，提供给他人阅览，请勿收藏非法违规的网页地址。</div>
          </div>
          <div class="services-list">
            <div class="text">每位微信用户可以关注IF2C.CN公众号，获取自己的网页地址，以及已保存的网页地址。</div>
          </div>
          <div class="services-list">
            <div class="text">为了提高服务器的稳定性，IF2C.CN的提供的服务都将在阿里云服务器上运行，以保证用户数据安全。</div>
          </div>
          <div class="services-list">
            <div class="text">IF2C.CN提供的网络收藏夹服务，是基于Chromium的内核浏览器开发的，主流浏览器均可使用，如：360浏览器、Chrome、Opera、火狐以及最新版本的Edge等，由于时间成本以及不必要的开发，将不考虑IE兼容问题。</div>
          </div>
          <div class="services-list">
            <div class="title">用户违规行为</div>
          </div>
          <div class="services-list">
            <div class="text">网页涉嫌色情，暴利宣传，恐怖活动，传播分裂国家等相关信息。</div>
          </div>
          <div class="services-list">
            <div class="text">危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的。</div>
          </div>
          <div class="services-list">
            <div class="text">煽动民族仇恨、民族歧视，破坏民族团结的，破坏国家宗教政策，宣扬邪教和封建迷信的，散布谣言，扰乱社会秩序，破坏社会稳定的。</div>
          </div>
          <div class="services-list">
            <div class="text">网页有大量广告，不限于赌博诱导性广告。</div>
          </div>
          <div class="services-list">
            <div class="text">网页有钓鱼嫌疑，不得分享金融性网页。</div>
          </div>
          <div class="services-list">
            <div class="text">网页涉嫌谣言、不实信息、宣传邪教等信息。</div>
          </div>
          <div class="services-list">
            <div class="text">侵犯其他用户或第三合法利益、干扰他人工作等。</div>
          </div>
          <div class="services-list">
            <div class="text">触犯以上违规行为时，情节严重者可能会被禁止使用。</div>
          </div>
        </div>
        <div class="trans-control flex-center">
          <div class="confirm" @click="cancel()">朕知道了</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
  props:{
    sta:Boolean
  },
  methods:{
    cancel(){
      this.$emit('update:sta', false);
    }
  }
}
</script>

<style scoped>

</style>