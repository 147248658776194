<template>
  <div>
    <div class="content-top flex-center-between">
      <div class="title flex-center">
        <div class="text">导入草稿箱</div>
        <div class="samll-title flex-center-between" >草稿箱已保存{{draftNum}}条链接</div>
      </div>
      <div class="content-control flex-center-between">
        <div class="share-import">
          <input type="file" @change="changeBook" ref="changeBook" accept="text/html" id="import" style="display: none;">
          <label for="import">
            <div class="title flex-center">
              <div class="text">导入</div>
              <i class="iconfont icon-wenjianjia"></i>
            </div>
          </label>
        </div>
      </div>
    </div>

    <div class="draft-container">
      <div class="draft-list" v-for="link in list" :class="{checked:link.is_handle}">
        <div class="list-container flex">
          <div class="list-content flex-col">
            <div class="title-wrapper flex-center-between">
              <div class="title nowrap" :title="link.title">{{link.title}}</div>
              <div class="draft-check nowrap flex-center" :class="{active:link.is_handle}" @click="link.is_handle = !link.is_handle">
                <i class="iconfont icon-check1"></i>
              </div>
            </div>
            <div class="list-footer flex-center-between">
              <a class="nowrap" rel="noopener noreferrer" target="_blank" :href="link.link" title="点击跳转">{{link.link}}</a>
              <div class="time">导入于{{link.create_time}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="draft-nothing flex-center" v-if="list.length < 1">
      <div class="d-nothing-container flex-col-center">
        <div class="nothing-img"></div>
        <p class="text">暂无草稿链接，可以将浏览器的书签栏导出的文件导入进来</p>
        <div class="first-add flex-center">
          <input data-v-404c8757="" type="file" accept="text/html" id="import" style="display: none;">
          <label for="import" class="flex-center">
            <div class="iconfont icon-zengjia"></div>
            <div class="text">导入收藏夹</div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {uploadImportBook,getAllImportList} from '@/api/apis'
import store from '../store/index'
export default {
  name: "Draft",
  data(){
    return{
      list:[]
    }
  },
  watch:{
    draftList(info){
      if(info === 'new'){
        this.getAllList();
      }
    }
  },
  computed:{
    draftList(){
      return store.state.draftList;
    },
    draftNum(){
      return store.state.draftNum;
    },
  },
  methods:{
    changeBook(info){
      let formData = new FormData();
      let file = info.target.files[0];
      if(file.type !== 'text/html'){
        this.toast('文件格式错误');
        return false;
      }
      if(file.size > 100 * 1024){
        this.toast('文件大小最多100K');
        return false;
      }
      formData.append("file", info.target.files[0]);
      this.$refs.changeBook.value = '';
      uploadImportBook(formData).then((res)=>{
        if(res.data.group && res.data.group.length > 0){
          store.commit('set_importGroupArr',res.data.group);
          store.commit('set_showImportGroupSta',true);
        }
        this.getAllList();
        this.getUserAllLinksNum();
      })
    },
    getAllList(){
      getAllImportList().then((res) => {
        this.list = res.data;
        store.commit('set_draft_list',this.list);
      })
    }
  },
  created(){
    this.getAllList();
  }
}
</script>

<style scoped>
.nothing{
  display: none;
}
.draft-nothing{
  position: absolute;
  top: 70px;
  left: 0;
  height: calc( 100% - 140px );
  width: 100%;
  animation: nothing-show 0.4s ease-in-out forwards;
}
.d-nothing-container{
  animation: nothing-scale 0.4s ease-in-out forwards;
}
.draft-nothing .nothing-img{
  background-repeat: no-repeat;
  height: 120px;
  width: 120px;
  background-image: url(../assets/svg/electricity.svg);
  background-size: cover;
  margin-bottom: 20px;
}
.draft-nothing .text{
  color: #dedede;
  font-size: 20px;
}
.draft-nothing label{
  cursor: pointer;
}
.draft-title,
.title-wrapper .draft-check{
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 5px;
  background-color: #e3ebfa;
}
.draft-check{
  cursor: pointer;
  transition: all .25s cubic-bezier(.215,.61,.355,1);
  height: 26px;
  flex: 0 0 32px;
}
.draft-check .iconfont{
  color: #888;
}
.draft-check:hover .iconfont{
  color: #2643f7;
}
.draft-check.active{
  background-color: #2643f7;
}
.draft-check:hover .iconfont{
  color: #2643f7;
}
.draft-check.active .iconfont,
.draft-check.active:hover .iconfont{
  color: #fff;
}
.draft-container{
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(400px,1fr));
  grid-gap: 20px;
}
.draft-list{
  border-radius: 8px;
  box-shadow: 0 0 20px rgb(193 201 255 / 50%);
  background-color: #fff;
  overflow: hidden;
  transition: all .25s cubic-bezier(.215,.61,.355,1);
  border: solid 4px transparent;
}
.draft-list .list-container{
  margin: -4px;
  align-items: stretch;
  -webkit-align-items: stretch;
}
.draft-list.checked,
.draft-list:hover{
  border-color: #0023ff;
}
.draft-list .list-content{
  flex: 1;
  padding:12px 15px 12px 12px;
  overflow: hidden;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}
.draft-list .list-content .title{
  font-weight: bold;
  padding-right: 10px;
}
.draft-list .list-content .text{
  color: #888;
  font-size: 12px;
  height: 35px;
  overflow: hidden;
}
.draft-list .list-content .time{
  text-align: right;
  font-size: 12px;
}
.draft-list .list-content a{
  flex: 1;
  color: #2643f7;
  padding-right: 10px;
}
.draft-list .list-footer{
  align-items: baseline;
  -webkit-align-items: baseline;
}
</style>