import {
    res
} from './res'

//获取链接列表
export function getLinkList(group_id,sort,page,limit = 15) {
    return res({
        url: '/api/links/getList',
        method: 'post',
        data: {
            group_id:group_id,
            page:page,
            sort:sort,
            limit:limit
        }
    })
}
//设置链接分享状态
export function setLinkShare(list) {
    return res({
        url: '/api/links/setLinkShare',
        method: 'post',
        data: {
            list:list
        }
    })
}
//获取所有分类
export function getGroupList() {
    return res({
        url: '/api/group/getUserGroup',
        method: 'post',
        data: {

        }
    })
}
//获取网站TDK
export function getWebInfo(url) {
    return res({
        url: '/api/links/getWebInfo?url='+url,
        method: 'get',
        data: {
            // url:url
        }
    })
}
//保存网页链接
export function saveLinks(data) {
    return res({
        url: '/api/links/save',
        method: 'post',
        data: data
    })
}
//PING网址
export function pingLinks(url) {
    return res({
        url: '/api/links/ping?url='+url,
        method: 'get',
        data: {}
    })
}
//删除网址
export function deleteLinks(id) {
    return res({
        url: '/api/links/delLink',
        method: 'post',
        data: {
            id:id
        }
    })
}
//获取用户总链接数量
export function getUserAllLinksNum() {
    return res({
        url: '/api/links/getUserAllLinksNum',
        method: 'get',
        data: {

        }
    })
}
//添加分类
export function addGroup(name) {
    return res({
        url: '/api/group/saveGroup',
        method: 'post',
        data: {
            name:name
        }
    })
}
//修改分类名称
export function editGroupName(id,name) {
    return res({
        url: '/api/group/editGroupName',
        method: 'post',
        data: {
            id:id,
            name:name
        }
    })
}
//删除分类
export function delGroup(id,type) {
    return res({
        url: '/api/group/delGroup',
        method: 'post',
        data: {
            id:id,
            type:type
        }
    })
}
//批量删除链接
export function delAllLink(ids,type = 1) {
    return res({
        url: '/api/links/delAllLink',
        method: 'post',
        data: {
            ids:ids,
            type:type
        }
    })
}
//批量移动分类
export function moveLinkGroup(ids,group_id,type = 1) {
    return res({
        url: '/api/links/moveLinkGroup',
        method: 'post',
        data: {
            ids:ids,
            group_id:group_id,
            type:type
        }
    })
}
//获取回收站链接列表
export function getRecycleList(page = 1,limit = 15) {
    return res({
        url: '/api/links/getRecycleList?page='+page+'&limit='+limit,
        method: 'get',
        data: {
        }
    })
}
//撤销回收站链接
export function revokeLink(ids) {
    return res({
        url: '/api/links/revokeLink',
        method: 'post',
        data: {
            ids:ids,
        }
    })
}
//清除回收站链接
export function clearLink(ids) {
    return res({
        url: '/api/links/clearLink',
        method: 'post',
        data: {
            ids:ids,
        }
    })
}
//获取用户信息
export function getUserInfo() {
    return res({
        url: '/api/user/getUserInfo',
        method: 'post',
        data: {

        }
    })
}
//设置用户显示模式
export function setUserViewType(type) {
    return res({
        url: '/api/user/setUserViewType',
        method: 'post',
        data: {
            type:type
        }
    })
}
//设置用户分组排序
export function setUserGroupSort(type,state) {
    return res({
        url: '/api/user/setUserGroupSort',
        method: 'post',
        data: {
            type:type,
            state:state
        }
    })
}
//搜索链接
export function searchLink(link) {
    return res({
        url: '/api/links/searchLink',
        method: 'post',
        data: {
            link:link
        }
    })
}
//搜索链接
export function saveFeedback(info) {
    return res({
        url: '/api/user/saveFeedback',
        method: 'post',
        data: {
            info:info
        }
    })
}
//搜索链接
export function setLinkClickNum(info) {
    return res({
        url: '/api/links/setLinkClickNum',
        method: 'post',
        data: {
            id:info
        }
    })
}
//退出登录
export function logout() {
    return res({
        url: '/api/user/logout',
        method: 'post',
        data: {

        }
    })
}
//导入
export function uploadImportBook(data) {
    return res({
        url: '/api/links/uploadImportBook',
        method: 'post',
        data: data
    })
}
//获取所有导入的链接
export function getAllImportList() {
    return res({
        url: '/api/links/getAllImportList',
        method: 'post',
        data: {}
    })
}
//保存导入的书签里面的分类
export function saveImportGroup(group) {
    return res({
        url: '/api/links/saveImportGroup',
        method: 'post',
        data: {
            group:group
        }
    })
}
//设置分类加密
export function setGroupLock(group_id,is_lock,password) {
    return res({
        url: '/api/group/setLock',
        method: 'post',
        data: {
            group_id:group_id,
            is_lock:is_lock,
            password:password,
        }
    })
}