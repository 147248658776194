<template>
  <div>
    <!-- 设置加密访问 -->
    <div class="trans-mask pass-category-trans flex-center" :class="{show:sta}">
      <div class="mask-container">
        <div class="mask-content flex-col-center">
          <div class="random-text" @click="makePass">生成密码</div>
          <div class="title">设置加密访问</div>
          <div class="text">正在为<mark>{{group_info.name}}</mark>类设置加密，当其他用户访问你的主页时，<br>TA需要输入密码才能访问该类</div>
          <div class="password-container flex-center" title="点击可设置加密" @click="addLock" :class="{active:group_info.is_lock}">
            <div class="password-wrapper flex">
              <div class="flex-col-center">
                <div class="state-text a">未加密</div>
                <i class="iconfont icon-jiesuo"></i>
              </div>
              <div class="flex-col-center">
                <div class="state-text b">已加密</div>
                <i class="iconfont icon-suoding"></i>
              </div>
            </div>
          </div>
          <div class="password-text">{{group_info.password || ''}}</div>
        </div>
        <div class="trans-control flex-center">
          <div class="confirm" @click="confirmLock">确认</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {setGroupLock} from '@/api/apis'
export default {
  name: "Pass",
  props:{
    sta:{
      type:Boolean,
    },
    group_info:{
      type:Object
    }
  },
  watch:{
    group_info(info){
      
    }
  },
  methods:{
    makePass(){
      this.group_info.password = this.rand(1000,9999);
    },
    rand(min,max){
      return Math.floor(Math.random() * (max - min)) + min;
    },
    addLock(){
      this.group_info.is_lock = this.group_info.is_lock ? 0 : 1;
    },
    confirmLock(){
      if(this.group_info.is_lock && !this.group_info.password){
        this.toast('请先生成密码');
        return false;
      }
      let lock = this.group_info.is_lock ? 1 : 0;
      let password = this.group_info.password ? this.group_info.password : '';
      setGroupLock(this.group_info.id,lock,password).then(()=>{
        this.toast('设置成功',2);
        this.$emit('update:sta',false);
      });
    },
  },
  created(){
    console.log(111);
  },
  mounted(){
    console.log(2222);
  }
}
</script>

<style scoped>

</style>