<template>
  <div>
    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'App',
  data(){
    return{

    }
  },
  methods:{

  },
  created(){
    
  }
}
</script>
