<template>
  <div class="image-container" :class="{radio:status}">
    <div class="image-list show" :class="{checked:link.is_handle}" v-for="link in list">
<!--      <img class="image-bg" :class="{done:link.image_success}" @load="imgLoadSuccess(link)" :src="link.image || ''">-->
      <img class="image-bg done" :src="link.image || ''">
      <div class="share-radio flex-center" :class="{'share-active':link.is_share}" @click="link.is_share = !link.is_share" title="正在设置分享状态">
        <div class="state-container"></div>
      </div>
      <div class="image-top flex-center-between">
        <div class="mask-control flex-center">
          <div class="control-list flex-center link-edit" @click="edit(link)" title="重新编辑标题">
            <div class="iconfont icon-bianji"></div>
          </div>
          <div class="control-list flex-center link-delete" @click="edit(link,2)" title="删除网页">
            <div class="iconfont icon-cancel"></div>
          </div>
          <div class="control-list flex-center link-message" @click="edit(link,3)" title="查看网页信息">
            <div class="iconfont icon-tishi"></div>
          </div>
          <div class="control-list flex-center link-copy" v-clipboard:copy="link.link" v-clipboard:success="copySuccess" title="复制链接">
            <div class="iconfont icon-fuzhi"></div>
          </div>
        </div>
        <div class="select-link flex-center" :class="{active:link.is_handle}" @click="link.is_handle = !link.is_handle">
          <div class="iconfont icon-check1"></div>
        </div>
      </div>
      <a :href="link.link" rel="noopener noreferrer" target="_blank"  @click="setLinkClickNum(link.id)" class="image-wrapper flex-col">
        <div class="null"></div>
        <div class="image-footer  flex-center-between">
          <div class="footer-ico">
            <img :src="link.ico" @error="icoLoadError(link)">
          </div>
          <div class="footer-info flex-col">
            <div class="footer-title nowrap" :title="link.title || link.link">{{link.title || link.link}}</div>
            <div class="flex-center-between">
              <div class="footer-link nowrap">{{link.link}}</div>
              <div class="time">{{link.time_tran}}</div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageMode",
  props: {
    status: Boolean,
    list: Array,
    shareMulti:Number
  },
  watch:{
    shareMulti(type){
      if(type > 0){
        this.list.map((item)=>{
          if(type === 1){
            item.is_share = true;
          }else if(type === 2){
            item.is_share = false;
          }else{
            item.is_share = !item.is_share;
          }
        });
        this.$emit('initShareMulti',0);
      }
    }
  },
  methods:{
    edit(info,type = 1){
      this.$emit('edit',info,type);
    }
  }
}
</script>

<style scoped>
.share-radio.share-active .state-container {
  background-color: #3dca8d;
}
.share-radio.share-active .state-container:after {
  transform: translate3d(26px,0,0);
}
.image-container{
  display: grid;
  grid-template-columns:repeat(auto-fill,minmax(380px,1fr));
  grid-column-gap:20px;
  grid-auto-rows:10px;
}
.image-container .image-list.show{
  visibility:visible !important;
  opacity: 1 !important;
}
.image-container .image-list{
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  grid-row:auto / span 37;
  border-radius: 8px;
  overflow: hidden;
  transition:all .25s ease;
}
.image-container .image-wrapper{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: space-between;
  transition:all .4s cubic-bezier(.215,.61,.355,1);
  z-index: 2;
}
.image-container .image-list > img{
  width:100%;
  height: 100%;
}
.image-list .footer-ico{
  flex:0 0 30px;
}
.image-list .footer-ico img{
  width: 32px;
  height: 32px;
}
.image-list .footer-info{
  flex: 1;
  padding-left: 15px;
  padding-right: 5px;
  overflow: hidden;
}
.image-list .time{
  flex:0 0 110px;
  text-align: right;
}
.image-list .select-link{
  cursor: pointer;
  background-color: #fff;
  width: 50px;
  height: 26px;
  border-radius: 35px;
  text-align: center;
  box-shadow: 4px 12px 40px 6px rgba(0,0,0,.1);
  opacity: 0;
  visibility:hidden;
  transition:transform .4s cubic-bezier(.215,.61,.355,1);
  transform: translateX(5px);
}
.image-list .select-link .iconfont{
  font-size: 20px;
  transition:color .15s ease;
}
.image-list .select-link.active{
  background-color: #2643f7;
}
.image-list .select-link.active .iconfont{
  color: #fff !important;
}
.image-list .select-link:hover .iconfont{
  color: #2643f7;
}
.image-container.radio .image-top{
  display: none;
}
.image-list .mask-control{
  position: relative;
  background-color: #fff;
  padding: 0 10px;
  border-radius: 35px;
  box-shadow: 4px 12px 40px 6px rgb(0 0 0 / 9%);
  transform: translateX(-5px);
}
.image-list a:after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: calc( 100% - 8px );
  width: calc( 100% - 8px );
  border-radius: 8px;
  border: solid 4px transparent;
}
.image-list:hover a:after,
.image-list.checked a:after{
  border-color: #0023ff;
}
.image-list:after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition:.4s cubic-bezier(.215,.61,.355,1);
  z-index: 1;
  background-image: linear-gradient(180deg,rgba(0,0,0,.34) 0,rgba(0,0,0,.338) 3.5%,rgba(0,0,0,.324) 7%,rgba(0,0,0,.306) 10.35%,rgba(0,0,0,.285) 13.85%,rgba(0,0,0,.262) 17.35%,rgba(0,0,0,.237) 20.85%,rgba(0,0,0,.213) 24.35%,rgba(0,0,0,.188) 27.85%,rgba(0,0,0,.165) 31.35%,rgba(0,0,0,.144) 34.85%,rgba(0,0,0,.126) 38.35%,rgba(0,0,0,.112) 41.85%,rgba(0,0,0,.103) 45.35%,rgba(0,0,0,.1) 48.85%,rgba(0,0,0,.103) 52.35%,rgba(0,0,0,.112) 55.85%,rgba(0,0,0,.126) 59.35%,rgba(0,0,0,.144) 62.85%,rgba(0,0,0,.165) 66.35%,rgba(0,0,0,.188) 69.85%,rgba(0,0,0,.213) 73.35%,rgba(0,0,0,.237) 76.85%,rgba(0,0,0,.262) 80.35%,rgba(0,0,0,.285) 83.85%,rgba(0,0,0,.306) 87.35%,rgba(0,0,0,.324) 90.85%,rgba(0,0,0,.338) 94.35%,rgba(0,0,0,.347) 97.85%,rgba(0,0,0,.35));
}
.image-container.radio .image-list:after,
.image-list:hover:after{
  visibility: visible;
  opacity: 1;
}
.image-container.radio .footer-title,
.image-container.radio .footer-link,
.image-container.radio .time{
  color: #3e3737 !important;
}
.image-list:hover .mask-control,
.image-list:hover .select-link{
  opacity: 1;
  visibility:visible;
  transform: translateX(0);
}
.image-list .mask-control .control-list{
  padding:3.5px 5px;
}
.image-list .image-top{
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}
.image-list .image-footer{
  margin:10px 10px;
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgb(0 0 0 / 12%);
  padding: 5px 10px;
  border-radius: 8px;
}
.image-container .image-list:nth-child(5n+1){
  background-color: #a4e9fc;
}
.image-container .image-list:nth-child(5n+2){
  background-color: #D7E2F6;
}
.image-container .image-list:nth-child(5n+3){
  background-color: #F3F4F9;
}
.image-container .image-list:nth-child(5n+4){
  background-color: #e8f3c4;
}
.image-container .image-list:nth-child(5n+5){
  background-color: #F2F5F8;
}
.image-list .image-wrapper .footer-title,
.image-list .image-wrapper .footer-link,
.image-list .image-wrapper .time{
  font-size: 12px;
}
.image-list.show .image-wrapper .footer-title,
.image-list.show .image-wrapper .footer-link,
.image-list.show .image-wrapper .time{
  color: #fff;
}
</style>