<template>
<div class="web-container" :class="{radio:status}">
  <div class="web-list" :class="{checked:link.is_handle}" v-for="link in list">
    <div class="list-container">
      <a class="list-wrapper" rel="noopener noreferrer" target="_blank" :href="link.link" @click="setLinkClickNumFn(link)">
        <div class="link-head">
<!--          <img :src="link.image || ''" :class="{done:link.image_success}" @load="imgLoadSuccess(link)">-->
          <img :src="link.image || ''" class="done">
        </div>
        <div class="link-content flex-center-between">
          <div class="info">
            <div class="text nowrap">{{link.title || link.link}}</div>
            <div class="category nowrap" >{{link.group_name || '未分类'}}</div>
          </div>
          <div class="ico">
            <img :src="link.ico" @error="icoLoadError(link)">
          </div>
        </div>
        <div class="web-footer ">
          <div class="message nowrap-2" :title="link.info || '未编辑内容'" v-html="link.info || '未编辑内容'"></div>
          <div class="flex-center-between">
            <div class="data-container flex">
              <div class="hot flex-center" :title="link.click > 0 ? '网址的点击总量' : '未有点击量'">
                <i class="iconfont icon-hot"></i>
                <div class="hot-text">{{link.click || '-'}} 次点击</div>
              </div>
              <div class="cellction flex-center" :title="link.collection > 0 ? '其他用户收藏量' : '未有其他用户收藏'">
                <i class="iconfont icon-shoucang"></i>
                <div class="cellction-text">{{link.collection || '-'}} 次收藏</div>
              </div>
            </div>
            <div class="date nowrap">{{link.time_tran}}</div>
          </div>
        </div>
      </a>
      <div class="select-link flex-center" :class="{active:link.is_handle}" @click="link.is_handle = !link.is_handle">
        <div class="iconfont icon-check1"></div>
      </div>
      <div class="mask-control flex-center">
        <div class="control-list flex-center link-edit" @click="edit(link)" title="重新编辑标题">
          <div class="iconfont icon-bianji"></div>
        </div>
        <div class="control-list flex-center link-delete" @click="edit(link,2)" title="删除网页">
          <div class="iconfont icon-cancel"></div>
        </div>
        <div class="control-list flex-center link-message" @click="edit(link,3)" title="查看网页信息">
          <div class="iconfont icon-tishi"></div>
        </div>
        <div class="control-list">
          <div class="copy" title="点击可复制地址" @click.prevent v-clipboard:copy="link.link" v-clipboard:success="copySuccess">
            <div class="iconfont icon-fuzhi"></div>
          </div>
        </div>
      </div>
      <div class="share-radio flex-center" :class="{'share-active':link.is_share}" @click.stop="link.is_share = !link.is_share" title="正在设置分享状态">
        <div class="state-container"></div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "CardMode",
  props: {
    status: Boolean,
    list: Array,
    shareMulti:Number
  },
  watch:{
    shareMulti(type){
      if(type > 0){
        this.list.map((item)=>{
          if(type === 1){
            item.is_share = true;
          }else if(type === 2){
            item.is_share = false;
          }else{
            item.is_share = !item.is_share;
          }
        });
        this.$emit('initShareMulti',0);
      }
    }
  },
  data(){
    return{

    }
  },
  methods:{
    edit(info,type = 1){
      this.$emit('edit',info,type);
    },
    setLinkClickNumFn(info){
      this.setLinkClickNum(info.id);
      info.click += 1;
    }
  }
}
</script>
<style scoped>

.data-container .iconfont{

}
.share-radio.share-active .state-container {
  background-color: #3dca8d;
}
.share-radio.share-active .state-container:after {
  transform: translate3d(26px,0,0);
}
</style>