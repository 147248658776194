<template>
  <div class="recycle-container flex-center" :class="{show:confirmSta}">
    <div class="recycle-content flex-center">
      <div class="recycle-wrapper flex-center">
        <div class="iconfont icon-shanchu"></div>
        <div class="text">{{confirmData.title}}</div>
        <div class="message">{{confirmData.info}}</div>
      </div>
      <div class="recycle-control flex-center-between">
        <div class="cancel" @click="cancel">取消</div>
        <div class="confirm" @click="confirm">确认</div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store/index';
export default {
  name: "Confirm",
  computed:{
    confirmSta(){
      return store.state.confirmSta;
    },
    confirmData(){
      return store.state.confirmData;
    },
  },
  methods:{
    cancel(){
      store.commit('set_confirm_sta',false);
    },
    confirm(){
      store.commit('set_confirm_sta',false);
      let fn = store.state.confirmFn;
      if(typeof fn === 'function'){
        fn();
      }
    }
  }
}
</script>

<style scoped>
.recycle-container{
    position: fixed;
    bottom: 66px;
    left: 125px;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    z-index: 99;
    transition:all .4s cubic-bezier(.215,.61,.355,1);
}
.recycle-content{
    padding:15px 25px;
    box-shadow: 0 28px 50px rgb(25 24 40 / 35%);
    border-radius: 35px;
    transform: scale(0.92);
    transition: all .4s cubic-bezier(.215,.61,.355,1);
    opacity: 0;
    visibility: hidden;
    background-color: #282828;
}
.recycle-container.show,
.recycle-container.show .recycle-content{
    opacity: 1;
    visibility: visible;
}
.recycle-container.show .recycle-content{
  transform:scale(1);
}
.recycle-wrapper .text,
.recycle-wrapper .message{
    color: #fff;
    margin:0 5px;
}
.recycle-wrapper .iconfont{
  color: #f72626;
  margin-right: 5px;
}
.recycle-control div{
  cursor: pointer;
  color: #fff;
  border-radius: 5px;
  padding: 0 8px;
  background-color: #3c3d3e;
  transition: .15s ease;
}
.recycle-control div:hover{
  background-color: #565b61;
}
.recycle-control div:first-child{
  margin-right: 5px;
}
</style>