import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        in_loading:false,
        show_edit:false,
        group_list:[],
        group_sort:1,
        toastSta:false,
        toastType:1,
        toastMsg:'',
        getList:0,
        editData:{},
        show_delete:false,
        searchGroupId:0,
        linkList:[1],
        allLinkNum:0,
        recycleNum:0,
        draftNum:0,
        recycleList:[1],
        userInfo:{view_type:0},
        choiceGroupName:'全部收藏',
        searchName:'',
        searchChange:'',
        confirmData:{},
        confirmSta:false,
        confirmFn:'',
        draftList:[],
        showImportGroupSta:false,
        importGroupArr:[]
    },
    mutations:{
        set_loading(state, status) {
            state.in_loading = status;
        },
        set_edit(state, status) {
            state.show_edit = status;
        },
        set_group(state, list) {
            state.group_list = list;
        },
        set_group_sort(state, type) {
            state.group_sort = type;
        },
        set_toast(state, status) {
            state.toastSta = status;
        },
        set_toast_msg(state, msg) {
            state.toastMsg = msg;
        },
        set_toast_type(state, status) {
            state.toastType = status;
        },
        set_get_list(state, status) {
            state.getList = status;
        },
        set_edit_data(state, data) {
            state.editData = data;
        },
        set_delete(state, status) {
            state.show_delete = status;
        },
        set_group_id(state, group_id) {
            state.searchGroupId = group_id;
        },
        set_link_list(state, list) {
            state.linkList = list;
        },
        set_all_link_num(state, num) {
            state.allLinkNum = num;
        },
        set_recycle_num(state, num) {
            state.recycleNum = num;
        },
        set_recycle_list(state, list) {
            state.recycleList = list;
        },
        set_user_info(state, info) {
            state.userInfo = info;
        },
        set_user_info_group(state, info) {
            state.userInfo.last_group = info;
        },
        set_choice_group(state, info) {
            state.choiceGroupName = info;
        },
        set_search_name(state, info) {
            state.searchName = info;
        },
        set_search_change(state, info) {
            state.searchChange = info;
        },
        set_confirm_sta(state, sta) {
            state.confirmSta = sta;
        },
        set_confirm_fn(state, fn) {
            state.confirmFn = fn;
        },
        set_confirm_data(state, data) {
            state.confirmData = data;
        },
        set_draft_num(state, data) {
            state.draftNum = data;
        },
        set_draft_list(state, data) {
            state.draftList = data;
        },
        set_showImportGroupSta(state, data) {
            state.showImportGroupSta = data;
        },
        set_importGroupArr(state, data) {
            state.importGroupArr = data;
        },
    }
})