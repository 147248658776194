import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/flex.css'
import './assets/css/main.css'
import './assets/css/iconfont.css'
import func from '@/api/global_func'
import {getGroupList, getUserAllLinksNum,setLinkClickNum} from "@/api/apis";
import store from "@/store";

import VueClipboard  from 'vue-clipboard2'

Vue.use(func).use(VueClipboard);

Vue.config.productionTip = false

Vue.prototype.toast = (msg,type = 1) =>{
  func.toast(msg,type);
}


//获取所有链接的数量
Vue.prototype.getUserAllLinksNum = ()=>{
  getUserAllLinksNum().then((info)=>{
    store.commit('set_all_link_num',info.data.num);
    store.commit('set_recycle_num',info.data.recycle_num);
    store.commit('set_draft_num',info.data.draft_num);
  })
}
Vue.prototype.copySuccess = ()=>{
  func.toast('复制成功');
}
Vue.prototype.$confirm = (title,info,fn)=>{
  store.commit('set_confirm_data',{title, info});
  store.commit('set_confirm_sta',true);
  store.commit('set_confirm_fn',fn);
}

Vue.prototype.setGroupSort = (type)=>{
  let group = JSON.parse(JSON.stringify(store.state.group_list));
  group.sort((a,b)=>{
    if(type === 1){
      return parseInt(b.id) - parseInt(a.id);
    }else{
      return parseInt(b.links_num) - parseInt(a.links_num);
    }
  });
  store.commit('set_group',group);
}
//获取所有分组信息
Vue.prototype.getGroup = ()=>{
  getGroupList().then((info)=>{
    store.commit('set_group',info.data);
    Vue.prototype.setGroupSort(store.state.userInfo.group_sort);
  })
}

Vue.prototype.icoLoadError = (link) => {
  link.ico = 'http://cdn.if2c.cn/svg/image.svg';
}

Vue.prototype.imgLoadSuccess = (link) => {
  // console.log('加载图');
  // if(!link.image_success){
    link.image_success = true;
  // }
}

Vue.prototype.setLinkClickNum = (id)=>{
  setLinkClickNum(id);
}
new Vue({
  router,
  render: h => h(App),
  created(){
    console.log(this.$route.query.code,'codecode');
    if(this.$route.query.code){
      window.localStorage.setItem('token',this.$route.query.code);
    }
  }
}).$mount('#app')