<template>
  <div>
    <!-- loading -->
    <div class="loading show" :class="{done:!inLoading}">
      <div class="loading-line"></div>
      <div class="loading-img">
        <img src="../assets/svg/cat.svg">
      </div>
    </div>
    <!-- bg -->
    <div class="left_bg"></div>
    <div class="bottom_bg">
      <img src="../assets/svg/bottom_right_line.svg" alt="">
    </div>
    <!-- 顶部 -->
    <div id="top" class="flex">
      <div class="logo-container flex-center">
        <img src="../assets/svg/if2c.svg">
        <div class="text">用户中心</div>
      </div>
      <div class="top-container flex-center-between">
        <div class="search-container flex-center-between">
          <div class="search-text nowrap">{{choiceGroupName}}</div>
          <div class="search-wrapper flex-center">
            <input type="text" v-model="searchName" placeholder="搜索链接" @keyup.enter="setSearchLink">
            <div class="search-button flex-center" @click="setSearchLink">
              <div class="iconfont icon-sousuo"></div>
            </div>
          </div>
        </div>
        <div class="other-container flex-center">
          <div class="control-container">
            <div class="control-swiper">
              <div class="control-wrapper flex-center">
                <div class="get-link flex-center">
                  <div class="iconfont icon-ditu_diqiu"></div>
                  <div class="text">我的主页地址</div>
                  <div class="get-link-mask flex-center">
                    <a :href="defaultHomeUrl+userInfo.iden" title="点击跳转" rel="noopener noreferrer" target="_blank">if2c.cn/{{userInfo.iden}}</a>
                    <div class="iconfont icon-copy1" title="复制主页地址" v-clipboard:copy="defaultHomeUrl+userInfo.iden" v-clipboard:success="copySuccess"></div>
                  </div>
                </div>
                <div class="move-link flex-center" @click="moveLinkGroup">
                  <div class="iconfont icon-feiji_qifei"></div>
                  <div class="text">移动其他分类</div>
                </div>
                <div class="delete-link flex-center" @click="deleteAllLink">
                  <div class="iconfont icon-shanchu"></div>
                  <div class="text">删除链接</div>
                </div>
                <div class="select-all-link flex-center" @click="selectAllLink">
                  <div class="iconfont icon-danxuanxuanzhong"></div>
                  <div class="text">选择全部</div>
                </div>
                <div class="add-link flex-center" @click="add">
                  <div class="iconfont icon-zengjia"></div>
                  <div class="text">添加新的收藏</div>
                </div>
              </div>
              <div class="control-wrapper flex">
                <div class="get-link flex-center">
                  <div class="iconfont icon-ditu_diqiu"></div>
                  <div class="text">我的主页</div>
                  <div class="get-link-mask smart flex-center">
                    <a :href="defaultHomeUrl+userInfo.iden" title="点击跳转" rel="noopener noreferrer" target="_blank">if2c.cn/{{userInfo.iden}}</a>
                    <div class="iconfont icon-copy1" title="复制主页地址" v-clipboard:copy="defaultHomeUrl+userInfo.iden" v-clipboard:success="copySuccess"></div>
                  </div>
                </div>
                <div class="move-link flex-center" @click="moveLinkGroup">
                  <div class="iconfont icon-feiji_qifei"></div>
                  <div class="text">移动</div>
                </div>
                <div class="delete-link flex-center" @click="deleteAllLink">
                  <div class="iconfont icon-shanchu"></div>
                  <div class="text">删除</div>
                </div>
                <div class="select-all-link flex-center" @click="selectAllLink">
                  <div class="iconfont icon-danxuanxuanzhong"></div>
                  <div class="text">选择</div>
                </div>
                <div class="add-link flex-center" @click="add">
                  <div class="iconfont icon-zengjia"></div>
                  <div class="text">添加</div>
                </div>
              </div>
            </div>
          </div>
          <div class="user-container flex-center">
            <div class="name nowrap" :title="userInfo.nickname">{{userInfo.nickname}}</div>
            <img :src="userInfo.head_image" alt="用户头像">
          </div>
        </div>
      </div>
    </div>
    <!-- 内容容器 -->
    <div id="container" class="flex">
      <!-- 左侧功能按钮 -->
      <div class="left">
        <div class="nav-container">
          <div class="nav-wrapper">
            <div class="collection-container">
              <div class="all-collection flex-center-between">
                <div class="wrapper all flex-center-between" :class="{active:groupId === 0}" @click="choiceGroup({id:0})">
                  <div class="iconfont icon-shoucang"></div>
                  <div class="text">全部收藏</div>
                  <div class="count">{{allNum}}</div>
                </div>
                <div class="rank flex-center" :class="{active:showGroupSortSta}">
                  <div class="rank-text flex-center" @click.stop="showGroupSortSta = true;">
                    <div class="text">排序</div>
                    <i class="iconfont icon-transfer"></i>
                  </div>
                  <div class="share-rank-wrapper">
                    <div class="rank-list" @click="groupSort(1)">根据创建时间</div>
                    <div class="rank-list" @click="groupSort(2)">根据数量</div>
                  </div>
                </div>
              </div>
              <div class="category-container" :style="{maxHeight:cate_height + 'px'}" v-if="allGroup.length > 0">
                <div class="list flex-center-between" :class="{active:groupId === group.id}" v-for="group in allGroup" @click="choiceGroup(group)" title="已设置访问密码">
                  <div class="text nowrap">{{group.name}}</div>
                  <div class="count" v-if="group.links_num > 0">{{group.links_num}}</div>
                  <div class="mask-control flex-center">
                    <div class="control-list flex-center category-edit" @click.stop="editGroupFn(group)" title="重新编辑类名">
                      <div class="iconfont icon-bianji"></div>
                    </div>
                    <div class="control-list flex-center category-delete" @click.stop="delGroupFn(group)" title="删除该类">
                      <div class="iconfont icon-cancel"></div>
                    </div>
                    <div class="control-list flex-center category-password" title="设置加密" @click.stop="passGroupFn(group)">
                      <div class="iconfont icon-suoding"></div>
                    </div>
                  </div>
                  <div class="category-lock" v-if="group.is_lock === 1">
                    <i class="iconfont icon-suoding"></i>
                  </div>
                </div>
              </div>
              <div class="add-category" title="输入完成后，回车可以添加分类">
                <div class="iconfont"></div>
                <input type="text" v-model="addGroupName" @keyup.enter="addGroup" placeholder="+ 添加新的收藏分类" maxlength="25">
              </div>
            </div>
          </div>
          <div class="nav-wrapper other-container">
            <div class="list recycle flex-center-between" :class="{active:choiceOther === 1}" @click="recycleFn">
              <div class="flex">
                <i class="iconfont icon-shanchu"></i>
                <div class="text">回收站</div>
              </div>
              <div class="count">{{recycleNum || ''}}</div>
              <div class="mask-title nowrap">删除后的网页链接或网络图片链接都在这里</div>
            </div>
            <div class="list draft flex-center-between" :class="{active:choiceOther === 2}" @click="importBookMarkBtn">
              <div class="flex">
                <i class="iconfont icon-wenjianjia"></i>
                <div class="text">导入草稿箱</div>
              </div>
              <div class="count">{{draftNum || ''}}</div>
              <div class="mask-title nowrap">本地导入后的网页链接都在这里</div>
            </div>
            <div class="list flex-center-between" @click="feedbackSta = true">
              <div class="flex">
                <i class="iconfont icon-bijibendiannao"></i>
                <div class="text">建议反馈</div>
              </div>
              <div class="mask-title nowrap">您提交的建议可能会改变IF2C.CN提供的服务</div>
            </div>
            <div class="list flex-center-between" @click="serviceSta = true">
              <div class="flex">
                <i class="iconfont icon-guanyu"></i>
                <div class="text">服务条款</div>
              </div>
              <div class="mask-title nowrap">查看IF2C.CN提供的服务详情</div>
            </div>
            <div class="list money flex-center-between" v-if="false">
              <div class="flex">
                <i class="iconfont icon-zan"></i>
                <div class="text">赞助我们</div>
              </div>
              <div class="mask-title nowrap">赞助IF2C.CN团队，感谢您勒</div>
            </div>
            <div class="list flex-center-between" @click="quit">
              <div class="flex">
                <i class="iconfont icon-kaiguan"></i>
                <div class="text">退出登录</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧内容区域 -->
      <div class="right">
        <div id="content" ref="content_box">
          <router-view ref="home" />
        </div>
        <div class="nothing flex-center" id="nothing" :class="{show:nowLinkNum < 1 && !choiceOther}">
          <div class="nothing-container flex-col-center">
            <img src="../assets/svg/mouse.svg">
            <div class="nothing-text"><mark>{{choiceGroupName}}</mark>还木有创建链接</div>
            <div class="first-add flex-center" @click="add">
              <div class="iconfont icon-zengjia"></div>
              <div class="text">添加新的收藏</div>
            </div>
          </div>
        </div>
        <footer class="footer-container flex-center">
          <div class="footer-wrapper flex-center">
            <div class="footer-list no-link">
              <div class="text">Copyright © 2021 IF2C.CN All Rights Reserved</div>
            </div>
            <div class="line">·</div>
            <div class="footer-logo">
              <img src="../assets/svg/IF2C_Black_2.svg">
            </div>
            <div class="footer-list">
              <a href="https://beian.miit.gov.cn/" target="_blank" class="text">蜀 ICP 备 15028704 号 - 2</a>
            </div>
          </div>
        </footer>
      </div>
    </div>
    <!-- trans title -->
    <div class="trans-title flex-center-end">
      <div class="toast-container flex-center">
        <div class="text flex-center">
          <div>正在解析</div>
          <div class="file-name nowrap"></div>
          <div>文件，可能需要一分钟，请耐心等待</div>
        </div>
        <img src="../assets/svg/lamp.svg">
      </div>
    </div>
    <!-- toast -->
    <div class="toast flex-center" :class="{show:toastSta,done:toastType === 1,error:toastType !== 1}">
      <div class="toast-container flex-center">
        <div class="text">{{toastMsg}}</div>
        <div class="iconfont" :class="{'icon-cancel':toastType === 1,'icon-check-ircle':toastType !== 1}"></div>
      </div>
    </div>
    <!-- 保存导入分类名称 -->
    <div class="trans-mask add-draft-title flex-center" :class="{show:showImportGroupSta}">
      <div class="mask-container">
        <div class="title flex">
          <div class="title-samll flex">
            <i class="iconfont icon-wenjianjia"></i>
            <div class="text">添加收藏分类名称</div>
          </div>
          <div class="info">已扫描到{{importGroupArr.length}}个收藏夹名称</div>
        </div>
        <div class="draft-title-container flex-wrap">
          <div class="list" v-for="g in importGroupArr">{{g}}</div>
        </div>
        <div class="add-draft-control flex-center-end">
          <div class="cancel" @click="closeImportGroupSta">取消</div>
          <div class="confirm" @click="saveImportGroupBtn">保存</div>
        </div>
      </div>
    </div>
    <!-- 编辑分类名称 -->
    <div class="trans-mask edit-category-trans flex-center" :class="{show:showEditGroupSta}">
      <div class="mask-container">
        <div class="mask-bg"><img src="/img/if2c.bf000ea2.svg"></div>
        <div class="mask-content">
          <div class="title flex">
            <div class="iconfont icon-bianji"></div>
            <div class="title-samll">编辑分类</div>
          </div>
          <input type="text" v-model="editGroupData.name" placeholder="自定义类名，请控制字符数量，最多8个字" >
        </div>
        <div class="mask-samll-control flex-center-end">
          <div class="cancel" @click="showEditGroupSta = false">取消</div>
          <div class="confirm" @click="confirmEditGroup">确认</div>
        </div>
      </div>
    </div>
    <!-- 删除分类 -->
    <div class="trans-mask delete-category-trans flex-center" :class="{show:showDelGroupSta}">
      <div class="mask-container">
        <div class="mask-content">
          <div class="title flex">
            <div class="text">删除分类</div>
            <div class="message">正在删除类名为装机必备,该类下有{{editGroupData.links_num}}个网页</div>
          </div>
          <div class="delete-category-container flex-center">
            <div class="list" :class="{active:editGroupData.del_type === 1}" @click="editGroupData.del_type = 1">仅删除分类，保留该类下的网页，网页将移动到全部收藏</div>
            <div class="list" :class="{active:editGroupData.del_type === 2}" @click="editGroupData.del_type = 2">删除分类，以及该类下的所有网页</div>
          </div>
        </div>
        <div class="mask-samll-control flex-center-end">
          <div class="cancel" @click="showDelGroupSta = false">取消</div>
          <div class="confirm" @click="confirmDelGroup">确认</div>
        </div>
      </div>
    </div>
    <!-- 批量删除 -->
    <div class="trans-mask delete-webs-trans flex-center" :class="{show:showDelAllLinkSta}">
      <div class="mask-container">
        <div class="mask-content flex-col-center">
          <div class="double-title flex-center-between">
            <div class="title">批量删除网页</div>
            <div class="get-info-loading"
                 :class="{
                    'delete-loading':showDelAllLinkSta,
                    'show':showDelAllLinkSta,
                    'done':showDelAllLinkSta,
                 }">
              <img src="">
              <div class="link-out">连接已超时，请重试</div>
              <div class="link-done">你已选中{{choiceLinkArr.length}}个网页，等待确认中</div>
            </div>
          </div>
          <div class="batch-container">
            <div class="batch-content">
              <dl class="sheel">
                <dt class="flex">
                  <div>ICO</div>
                  <div>链接地址</div>
                  <div>标题</div>
                  <div>描述内容</div>
                  <div>发布日期</div>
                  <div>点击</div>
                  <div>点赞</div>
                  <div>收藏</div>
                </dt>
                <div class="dd-container">
                  <div class="nothing flex-center">
                    <div class="nothing-container flex-col-center">
                      <!--<img src="../svg/nothing.svg">-->
                      <div class="text">已经没有可删除的链接了</div>
                    </div>
                  </div>
                  <dd class="dd-web flex" v-for="(link,key) in choiceLinkArr">
                    <div>
                      <img :src="link.ico">
                    </div>
                    <div class="nowrap">
                      <a class="nowrap" rel="noopener noreferrer" target="_blank" :href="link.link">{{link.link}}</a>
                    </div>
                    <div class="nowrap">{{link.title}}</div>
                    <div class="nowrap">{{link.info}}</div>
                    <div>{{link.create_time}}</div>
                    <div>{{link.click || 0}}</div>
                    <div>{{link.up || 0}}</div>
                    <div>{{link.collection || 0}}</div>
                    <div class="delete-reduce" @click="removeChoiceLink(key)">
                      <i class="iconfont icon-jianhao"></i>
                    </div>
                  </dd>
                </div>
              </dl>
            </div>
            <div class="batch-loading flex-center hide">
              <div class="batch-loading-container flex-col-center">
                <img src="svg/loading_new.svg">
                <div class="text">正在获取选中的网页</div>
              </div>
            </div>
          </div>
        </div>
        <div class="mask-samll-control flex-center-end">
          <div class="cancel" @click="showDelAllLinkSta = false">取消</div>
          <div class="confirm" @click="confirmDelAllLink">确认</div>
        </div>
      </div>
    </div>
    <!-- 移动分类 -->
    <div class="trans-mask move-web-trans flex-center" :class="{show:showMoveLinkGroupSta}">
      <div class="mask-container">
        <div class="mask-content">
          <div class="title flex-center-between">
            <div class="title-samll flex-center">
              <div class="iconfont icon-feiji_qifei"></div>
              <div class="text" >移动到其他分类</div>
            </div>
            <div class="info">共有{{allGroup.length}}个分类</div>
          </div>
          <div class="move-container flex-wrap">
            <div class="move-list flex-center" @click="choiceGroupFn(0)" :class="{active:choiceMoveGroup === 0}">
              <div class="text">全部收藏</div>
              <div class="count">{{allNum}}</div>
            </div>
            <div class="move-list flex-center" v-for="group in allGroup" @click="choiceGroupFn(group.id)" :class="{active:choiceMoveGroup === group.id}">
              <div class="text">{{group.name}}</div>
              <div class="count">{{group.links_num}}</div>
            </div>
          </div>
        </div>
        <div class="mask-samll-control flex-center-end">
          <div class="cancel" @click="showMoveLinkGroupSta = false">取消</div>
          <div class="confirm" @click="confirmMoveLinkGroup">确认</div>
        </div>
      </div>
    </div>
    <pass :sta.sync="showGroupPassSta" :group_info="groupPassInfo"></pass>
    <edit @done="done" :edit-data="editData"></edit>
    <deleteWeb @done="doneDelete" :edit-data="editData"></deleteWeb>
    <confirm></confirm>
    <services :sta.sync="serviceSta"></services>
    <feedback :sta.sync="feedbackSta"></feedback>
  </div>
</template>

<script>
// @ is an alias to /src
import edit from '../components/Edit'
import deleteWeb from '../components/Delete'
import confirm from '../components/Confirm'
import services from '../components/Services'
import feedback from '../components/Feedback'
import pass from '../components/Pass'
import store from '../store/index'
import {getUserAllLinksNum,saveImportGroup,addGroup,editGroupName,delGroup,delAllLink,moveLinkGroup,getUserInfo,logout,setUserGroupSort} from '@/api/apis'
export default {
  name: 'Home',
  components:{
    edit,
    deleteWeb,
    confirm,
    services,
    feedback,
    pass
  },
  data(){
    return{
      showGroupSortSta:false,
      showEditGroupSta:false,
      showDelGroupSta:false,
      showDelAllLinkSta:false,
      showMoveLinkGroupSta:false,
      showGroupPassSta:false,
      serviceSta:false,
      feedbackSta:false,
      addGroupName:'',
      editGroupData:{},
      choiceLinkArr:[],
      choiceMoveGroup:'',
      cate_height:300,
      choiceOther:0,
      searchName:'',
      delType:1,
      groupPassInfo:{},
      defaultHomeUrl:'//www.if2c.cn/'
    }
  },
  computed:{
    inLoading(){
      return store.state.in_loading
    },
    toastSta(){
      return store.state.toastSta
    },
    toastMsg(){
      return store.state.toastMsg
    },
    toastType(){
      return store.state.toastType
    },
    showImportGroupSta(){
      return store.state.showImportGroupSta
    },
    importGroupArr(){
      return store.state.importGroupArr
    },
    editData(){
      if(store.state.editData && (store.state.editData.handle_type === 2 || store.state.editData.handle_type === 3)){
        store.commit('set_delete', true);
      }else if(store.state.editData && store.state.editData.id > 0){
        store.commit('set_edit', true);
      }
      return store.state.editData
    },
    allGroup(){
      return store.state.group_list
    },
    groupId(){
      return store.state.searchGroupId;
    },
    nowLinkNum(){
      if(this.$route.path === '/main' || this.$route.path === '/search'){
        return store.state.linkList.length;
      }else if(this.$route.path === '/recycle'){
        return store.state.recycleList.length;
      }
      return 0;
    },
    allNum(){
      return store.state.allLinkNum;
    },
    recycleNum(){
      return store.state.recycleNum;
    },
    draftNum(){
      return store.state.draftNum;
    },
    userInfo(){
      return store.state.userInfo;
    },
    choiceGroupName(){
      return store.state.choiceGroupName;
    }
  },
  methods:{
    defaultBodyClick(){
      document.addEventListener("click",()=>{
        let len = document.getElementsByClassName("active").length;
        if(len){
          this.$nextTick(() => {
            this.showGroupSortSta = false;
            if(typeof this.$refs['home'].closeAllActive != 'undefined'){
              this.$refs['home'].closeAllActive();
            }
          })
        }
      });
    },
    add(){
      store.commit('set_edit_data', {});
      store.commit('set_edit', true);
    },
    done(){
      if(this.$route.path === '/search'){
        store.commit('set_search_change',new Date().getTime());
      }else if(this.$route.path === '/draft'){
        store.commit('set_draft_list','new');
      }else if(this.$route.path === '/recycle'){
        store.commit('set_recycle_list','new');
      }else{
        store.commit('set_get_list',new Date().getTime());
      }
      this.getUserAllLinksNum();
      this.getGroup();
    },
    doneDelete(){
      this.done();
    },
    // getUserAllLinksNum(){
    //   getUserAllLinksNum().then((info)=>{
    //     this.allNum = info.data.num;
    //     this.recycleNum = info.data.recycle_num;
    //     store.commit('set_all_link_num',info.data.num);
    //     store.commit('set_recycle_num',info.data.recycle_num);
    //   })
    // },
    choiceGroup(info){
      if(this.$route.path !== '/main'){
        this.$router.push('/main');
      }
      this.choiceOther = 0;
      store.commit('set_group_id',info.id);
      let group_name = '全部收藏';
      let group_num = store.state.allLinkNum;
      if(info.id){
        group_name = info.name;
        group_num = info.links_num;
      }
      store.commit('set_choice_group',group_name);
    },
    groupSort(type){
      store.commit('set_group_sort',type);
      this.setGroupSort(type);
      setUserGroupSort(type);
    },
    addGroup(){
      if(!this.addGroupName){
        this.toast('请输入名称',2);
        return false;
      }
      addGroup(this.addGroupName).then(()=>{
        this.toast('添加成功');
        this.addGroupName = '';
        this.getGroup();
      })
    },
    editGroupFn(info){
      this.editGroupData = JSON.parse(JSON.stringify(info));
      this.showEditGroupSta = true;
    },
    delGroupFn(info){
      info.del_type = 1;
      this.editGroupData = JSON.parse(JSON.stringify(info));
      this.showDelGroupSta = true;
    },
    confirmEditGroup(){
      editGroupName(this.editGroupData.id,this.editGroupData.name).then(()=>{
        if(store.state.searchGroupId === this.editGroupData.id){
          store.commit('set_choice_group',this.editGroupData.name);
        }
        this.toast('保存成功');
        this.showEditGroupSta = false;
        this.getGroup();
        if(this.$route.path === '/search'){
          store.commit('set_search_change',new Date().getTime());
        }else{
          store.commit('set_get_list',new Date().getTime());
        }
      });
    },
    confirmDelGroup(){
      delGroup(this.editGroupData.id,this.editGroupData.del_type).then(()=>{
        this.toast('删除成功');
        this.showDelGroupSta = false;
        this.getGroup();
        if(this.editGroupData.del_type === 2){
          this.getUserAllLinksNum();
        }
      })
    },
    selectAllLink(){
      let now_route = this.$route.path;
      let link = '';
      if(now_route === '/main' || now_route === '/search'){
        link = store.state.linkList;
      }else if(now_route === '/recycle'){
        link = store.state.recycleList;
      }else if(now_route === '/draft'){
        link = store.state.draftList;
      }else{
        return false;
      }
      let have_choice = false;
      link.some((item)=>{
        if(item.is_handle){
          have_choice = true;
          return true;
        }
      });
      link.some((item)=>{
        item.is_handle = !have_choice;
      });
    },
    getChoiceLinks(){
      let now_route = this.$route.path;
      this.choiceLinkArr = [];
      let link = '';
      if(now_route === '/draft'){
        link = store.state.draftList;
      }else if(now_route === '/recycle'){
        link = store.state.recycleList;
      }else{
        link = store.state.linkList;
      }
      link.some((item)=>{
        if(item.is_handle){
          this.choiceLinkArr.push(JSON.parse(JSON.stringify(item)));
        }
      });
    },
    deleteAllLink(){
      if(this.$route.path !== '/main'
          && this.$route.path !== '/search'
          && this.$route.path !== '/draft'
          && this.$route.path !== '/recycle'
      ){
        return false;
      }
      this.delType = 1;
      if(this.$route.path === '/draft'){
        this.delType = 2;
      }else if(this.$route.path === '/recycle'){
        this.delType = 3;
      }
      this.getChoiceLinks();
      if(this.choiceLinkArr.length < 1){
        this.toast('请至少选中一个网页');
        return false;
      }
      this.showDelAllLinkSta = true;
      //可以删除
    },
    moveLinkGroup(){
      if(this.$route.path !== '/main' && this.$route.path !== '/search' && this.$route.path !== '/draft'){
        return false;
      }
      this.delType = 1;
      if(this.$route.path === '/draft'){
        this.delType = 2;
      }
      this.getChoiceLinks();
      if(this.choiceLinkArr.length < 1){
        this.toast('请至少选中一个网页');
        return false;
      }
      this.choiceMoveGroup = '';
      this.showMoveLinkGroupSta = true;
      //可以移动
    },
    removeChoiceLink(key){
      this.choiceLinkArr.splice(key,1);
    },
    confirmDelAllLink(){
      if(this.choiceLinkArr.length < 1){
        this.toast('请至少选中一个网页');
        return false;
      }
      let ids = [];
      this.choiceLinkArr.map((item)=>{
        ids.push(item.id);
      });
      //可以删除
      delAllLink(ids,this.delType).then(()=>{
        this.toast('删除成功');
        this.showDelAllLinkSta = false;
        this.done();
      });
    },
    choiceGroupFn(info){
      this.choiceMoveGroup = info;
    },
    confirmMoveLinkGroup(){
      if(this.choiceLinkArr.length < 1){
        this.toast('请至少选中一个网页');
        return false;
      }
      if(this.choiceMoveGroup === ''){
        this.toast('请选择一个分类');
        return false;
      }
      let ids = [];
      this.choiceLinkArr.map((item)=>{
        ids.push(item.id);
      });
      //可以移动
      moveLinkGroup(ids,this.choiceMoveGroup,this.delType).then(()=>{
        this.toast('移动成功');
        this.showMoveLinkGroupSta = false;
        this.done();
      });
    },
    setPageHeight(){
      let body_height = document.body.clientHeight;
      this.cate_height = body_height - 520;
    },
    recycleFn(){
      if(this.$route.path !== '/recycle'){
        this.choiceOther = 1;
        store.commit('set_group_id','');
        this.$router.push('/recycle');
      }
    },
    importBookMarkBtn(){
      if(this.$route.path !== '/draft'){
        this.choiceOther = 2;
        this.$router.push('/draft');
      }
    },
    getUserInfo(){
      getUserInfo().then((info)=>{
        store.commit('set_user_info',info.data);
      });
    },
    setSearchLink(){
      if(this.searchName){
        store.commit('set_search_name',this.searchName);
        if(this.$route.path !== '/search'){
          this.$router.push('/search');
        }
      }
    },
    quit(){
      logout().then(()=>{
        //退出登录
        window.localStorage.removeItem('token');
        window.location.href = 'https://www.if2c.cn';
      });
    },
    closeImportGroupSta(){
      store.commit('set_showImportGroupSta',false);
    },
    saveImportGroupBtn(){
      let group = store.state.importGroupArr;
      if(group && group.length > 0){
        saveImportGroup(group).then(()=>{
          this.toast('导入分类成功',2);
          this.closeImportGroupSta();
          this.done();
        });
      }
    },
    passGroupFn(info){
      this.showGroupPassSta = true;
      this.groupPassInfo = info;
    }
  },
  created() {
    this.defaultBodyClick();
    this.getUserInfo();
    this.getUserAllLinksNum();
    this.$router.push('/main');
    this.setPageHeight();
    window.onresize = ()=>{
      this.setPageHeight();
    }
  }
}
</script>
