<template>
  <div>
    <div class="feedback-trans trans-mask flex-center" :class="{show:sta}">
      <div class="mask-container">
      	<div class="mask-bg"><img src="/img/if2c.bf000ea2.svg"></div>
        <div class="mask-content">
          <div class="title flex">
          	<div class="iconfont icon-bijibendiannao"></div>
          	<div class="title-samll">建议反馈</div>
          </div>
          <textarea v-model="info" cols="30" rows="5" placeholder="感谢您的建议，您的建议可能对我们非常重要，最多编辑200个字" autocomplete="off"></textarea>
        </div>
        <div class="mask-samll-control flex-center-end">
          <div class="cancel" @click="cancel">取消</div>
          <div class="confirm" @click="save">确认</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {saveFeedback} from "../api/apis";

export default {
  name: "Feedback",
  props:{
    sta:Boolean
  },
  data(){
    return{
      info:''
    }
  },
  watch:{
    sta(info){
      if(info){
        this.info = '';
      }
    }
  },
  methods:{
    cancel(){
      this.$emit('update:sta', false);
    },
    save(){
      if(!this.info){
        this.toast('请输入内容',2);
        return false;
      }
      if(this.info.length > 200){
        this.toast('最多输入200字',2);
        return false;
      }
      saveFeedback(this.info).then(()=>{
        this.toast('提交成功');
        this.$emit('update:sta', false);
      });
    }
  }
}
</script>

<style scoped>

</style>