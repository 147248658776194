<template>
  <div>
    <div class="trans-mask delete-web-trans flex-center" :class="{show:show}">
      <div class="mask-container">
        <div class="mask-bg">
          <img src="/img/if2c.bf000ea2.svg">
        </div>
        <div class="mask-content">
          <div v-if="editData.handle_type === 2" class="flex title">
             <i class="iconfont icon-shanchu"></i>
             <div class="title-samll">删除网页</div>
           </div>
           <div v-else class="flex title">
             <i class="iconfont icon-tishi"></i>
             <div class="title-samll">网页详细信息</div>
           </div>
          <div class="message-par flex-center-between">
            <div class="title">网页地址</div>
            <div class="text">
              <a class="nowrap-3 text" rel="nofollow" target="_blank" :title="'前往：'+editData.link" :href="editData.link">{{editData.link}}</a>
            </div>
          </div>
          <div class="message-par flex-center-between">
            <div class="title">分类</div>
            <div class="text">{{editData.group_name || '全部分类'}}</div>
          </div>
          <div class="message-par flex-center-between">
            <div class="title">Ping值</div>
            <div :class="'text '+editData.class_name">{{editData.ping}}</div>
          </div>
          <div class="message-par flex-center-between">
            <div class="title">发布于</div>
            <div class="text">{{editData.create_time}}</div>
          </div>
          <div class="message-par flex-center-between">
            <div class="title">发布用户</div>
            <div class="text">{{editData.user_name}}</div>
          </div>
          <div class="message-par flex-center-between">
            <div class="title">点击量</div>
            <div class="text">{{editData.click || '-'}}次点击</div>
          </div>
          <div class="message-par flex-center-between">
            <div class="title">网页描述</div>
            <div class="text">{{editData.info}}</div>
          </div>
        </div>
        <div class="mask-samll-control flex-center-end">
          <div class="cancel" @click="cancel">{{editData.handle_type === 2 ? '取消' : '关闭'}}</div>
          <div class="confirm" v-if="editData.handle_type === 2" @click="confirm">确认</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import store from "@/store";
import {pingLinks,deleteLinks} from "@/api/apis";
export default {
  name: "Delete",
  props:{
    editData:{
      type:Object
    }
  },
  data(){
    return{
      timeInterval:''
    }
  },
  computed:{
    show(){
      if(store.state.show_delete){
        this.ping();
      }else{
        clearInterval(this.timeInterval);
        this.timeInterval = '';
      }
      return store.state.show_delete
    }
  },
  methods:{
    cancel(){
      store.commit('set_delete', false);
    },
    ping(){
      pingLinks(encodeURI(this.editData.link)).then((info)=>{
        if(info.data.is_time_out){
          this.editData.ping = '超时';
        }else{
          this.editData.ping = info.data.second+'ms';
        }
        this.editData.class_name = info.data.class_name;
        this.timeInterval = setTimeout(()=>{
          this.ping();
        },1500);
      })
    },
    confirm(){
      deleteLinks(this.editData.id).then(()=>{
        this.toast('删除成功');
        this.cancel();
        this.$emit('done');
      });
    }
  }
}
</script>

<style scoped>

</style>