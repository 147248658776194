<template>
  <div>
    <div class="search-title flex-center-between">
      <div class="title">正在搜索“{{searchName}}”</div>
      <div class="text">搜索结果为{{list.length}}条</div>
    </div>
    <chartMode :list="list" @edit="edit" :search="true"></chartMode>
  </div>
</template>

<script>
import chartMode from '../components/mode/ChartMode'
import store from '../store/index'
import {searchLink} from '@/api/apis'
export default {
  name: "Search",
  components: {
    chartMode,
  },
  data(){
    return{
      list:[]
    }
  },
  watch:{
    searchName(info){
      this.searchLink(info);
    },
    searchChange(){
      this.searchLink(store.state.searchName);
    }
  },
  computed:{
    searchName(){
      return store.state.searchName;
    },
    searchChange(){
      return store.state.searchChange;
    }
  },
  methods:{
    searchLink(info){
      searchLink(info).then((info)=>{
        this.list = info.data;
        store.commit('set_link_list',this.list);
      });
    },
    edit(info,type){
      let tmp_data = JSON.parse(JSON.stringify(info));
      tmp_data.handle_type = type;
      tmp_data.ping = '';
      store.commit('set_edit_data', tmp_data);
    },
  },
  created(){
    this.searchLink(store.state.searchName);
  }
}
</script>

<style scoped>
.search-title{
  margin-bottom: 20px;
}
.search-title .title{
  flex: 1;
  font-size: 22px;
  font-weight: bold;
}
.search-title .text,
.title-wrapper .title-category{
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 5px;
  background-color: #e3ebfa;
}
.title-wrapper .title-category{
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 5px;
  background-color: #e3ebfa;
}
.result-container{
  display: grid;
  grid-gap:20px;
  grid-template-columns:repeat(auto-fill,minmax(655px,1fr));
  grid-template-rows:auto;
}
.chart-list{
  position: relative;
  border-radius: 8px;
  box-shadow: 0 0 20px rgb(193 201 255 / 50%);
  background-color: #fff;
  overflow: hidden;
  transition: all .25s cubic-bezier(.215,.61,.355,1);
  z-index: 1;
}
.chart-list .list-img{
  flex:0 0 150px;
  overflow: hidden;
}
.chart-list .list-img img{
  width: 100%;
  min-height: 100%;
}
.chart-list .list-container{
  align-items: stretch;
  -webkit-align-items: stretch;
}
.chart-list .list-content{
  flex: 1;
  padding:12px 15px;
  overflow: hidden;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}
.chart-list .list-content .title{
  font-weight: bold;
  padding-right: 10px;
}
.chart-list .list-content .text{
  color: #888;
  font-size: 12px;
  height: 35px;
  overflow: hidden;
}
.chart-list .list-content .time{
  text-align: right;
  flex:0 0 105;
  font-size: 12px;
}
.chart-list:hover .link{
  color: #2643f7;
}
.chart-list:hover .list-content .text{
  color: #282828;
}
.chart-list .list-content a{
  flex: 1;
  color: #2643f7;
  padding-right: 10px;
}
.result-container .chart-list:nth-child(5n+1) .list-img{
  background-color: #a4e9fc;
}
.result-container .chart-list:nth-child(5n+2) .list-img{
  background-color: #D7E2F6;
}
.result-container .chart-list:nth-child(5n+3) .list-img{
  background-color: #F3F4F9;
}
.result-container .chart-list:nth-child(5n+4) .list-img{
  background-color: #e8f3c4;
}
.result-container .chart-list:nth-child(5n+5) .list-img{
  background-color: #F2F5F8;
}
.chart-top{
  position: absolute;
  width: calc( 100% - 150px );
  z-index: 99;
  top: 0;
  left: 150px;
  padding: 8px 12px;
}
.chart-list .select-link{
  cursor: pointer;
  background-color: #fff;
  width: 75px;
  height: 26px;
  border-radius: 35px;
  text-align: center;
  box-shadow: 4px 12px 40px 6px rgba(0 0 0 / 9%);
  opacity: 0;
  visibility:hidden;
  transition:background-color .15s ease,transform .4s cubic-bezier(.215,.61,.355,1);
  transform: translateX(5px);
}
.chart-list .select-link .iconfont{
  transition:color .15s ease;
}
.chart-list .select-link:hover .iconfont{
  color: #2643f7;
}
.chart-list .mask-control{
  position: relative;
  top: 0;
  background-color: #fff;
  padding: 0 10px;
  border-radius: 35px;
  box-shadow: 4px 12px 40px 6px rgb(0 0 0 / 9%);
  transform: translateX(-5px);
}
.chart-list .mask-control .control-list{
  padding: 3px 5px;
}
.chart-list:hover .mask-control,
.chart-list:hover .select-link{
  opacity: 1;
  visibility:visible;
  transform: translateX(0);
}
</style>