<template>
  <div>
    <div class="trans-mask add-web-trans add flex-center" :class="{show:show}" @touchmove.prevent>
      <div class="mask-container">
        <div class="mask-content">
          <div class="input-list">
            <div class="get-info-loading" :class="{
              show:getLoading,
              out:getStatus === 1,
              done:getStatus === 2,
              error:getStatus === 3,
              null:getStatus === 4,
            }">
              <img src="../assets/svg/loading_new.svg">
              <div class="link-out" v-if="getStatus === 1">暂未获取到信息，链接已超时，可手动编辑网页信息</div>
              <div class="link-done" v-if="getStatus === 2">已获取到目标网页信息</div>
              <div class="link-error" v-if="getStatus === 3">请输入有效的网址</div>
              <div class="link-null" v-if="getStatus === 4">您还没有输入网址</div>
            </div>
            <div class="text" v-if="data.id > 0">已输入的网页地址 *</div>
            <div class="text" v-else>请输入网页地址 *</div>
            <div class="line"><img src="../assets/svg/line.svg"></div>
            <div class="edit-input" v-if="data.id > 0">{{data.link}}</div>
            <input v-else type="text" v-model="data.url" @blur="blurLink" placeholder="如：www.taobao.com">
          </div>
          <div class="select-category input-list">
            <div class="text">选择分类 *</div>
            <div class="select-category-container flex-wrap">
              <div class="list" :class="{active:data.group_id === 0}" @click="choiceGroup(0)">全部收藏</div>
              <div class="list" :class="{active:item.id === data.group_id}" v-for="item in group" @click="choiceGroup(item.id)">{{item.name}}</div>
            </div>
          </div>
          <div class="input-list flex-center-between">
            <div>
              <div class="text">是否分享</div>
              <div class="text-samll">分享网页可让其他用户在你的主页访问到该网页，这样或许可以提高你的主页价值，默认为分享</div>
            </div>
            <div class="share-radio" :class="{active:data.is_share}" @click="setLinkShare()">
              <div class="state-container">
              </div>
            </div>
          </div>
          <div class="input-list web-title-info">
            <div title="获取网页标题和网页描述内容" class="get-webinfo flex-center" @click="getWebInfo">
              <div class="iconfont icon-guanyu"></div> <div class="text">获取信息</div>
            </div>
            <div class="text">网页标题</div>
            <div class="text-samll">这是可选项，通常我们会自动获取目标网页描述及标题，你也可以自定义编辑该网页的内容描述</div>
            <textarea v-model="data.title" cols="30" rows="1" :placeholder="getStatus === 0 ? titlePl : '这个网页是什么呢'"></textarea>
          </div>
          <div class="input-list">
            <div class="text">网页描述</div>
            <textarea v-model="data.info" cols="30" rows="2" :placeholder="getStatus === 0 ? infoPl : '还可以对网页进行更详细的描述，minmax(10,300)'"></textarea>
          </div>
          <div class="circle"></div>
        </div>
        <div class="trans-control flex-center-end">
          <div class="cancel" @click="cancel">取消编辑</div>
          <div class="confirm" @click="save">确认{{data.id > 0 ? '编辑' : '添加'}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getWebInfo,saveLinks} from "@/api/apis";
import store from '../store/index'
export default {
  name: "Edit",
  props:{
    editData:{
      type:Object
    }
  },
  data(){
    return{
      data:{
        is_share:true,
        group_id:0,
      },
      group:[],
      getLoading:false,
      getStatus:0,
      titlePl:'正在获取网页标题...',
      infoPl:'正在获取网页描述内容...',
      oldLink:''
    }
  },
  computed:{
    show(){
      this.getStatus = 1;
      if(!this.group.length){
        this.group = store.state.group_list;
      }
      if(this.editData && this.editData.id > 0){
        this.data = JSON.parse(JSON.stringify(this.editData));
        this.data.url = this.data.link;
      }else{
        this.data = {
          is_share:true,
          group_id:store.state.userInfo.last_group,
        };
      }
      this.getLoading = false;
      return store.state.show_edit
    }
  },
  methods:{
    cancel(){
      this.getLoading = false;
      this.getStatus = 0;
      store.commit('set_edit', false);
    },
    setLinkShare(){
      this.data.is_share = !this.data.is_share;
    },
    choiceGroup(id){
      this.data.group_id = id;
    },
    getWebInfo(){
      if(this.getStatus === 0){
        return false;
      }
      this.getLoading = true;
      this.getStatus = 0;
      if(!this.data.url){
        this.getStatus = 4;
        return false;
      }
      getWebInfo(encodeURI(this.data.url)).then((info) => {
        if(info.data === false){
          this.getStatus = 1;
        }else{
          this.getStatus = 2;
          this.data.title = info.data.title;
          this.data.info = info.data.description;
          this.data.keywords = info.data.keywords;
          this.data.ico = info.data.ico;
        }
      }).catch(()=>{
        this.getStatus = 1;
      });
    },
    save(){
      if(!this.data.url){
        this.toast('请填写网页地址',2);
        return false;
      }
      // if(!this.data.title){
      //   this.toast('请填写网页标题',2);
      //   return false;
      // }
      this.data.share = this.data.is_share ? 1 : 0;
      saveLinks(this.data).then(()=>{
        //添加成功
        if(this.data.id && this.data.id > 0){
          this.toast('保存成功');
        }else{
          this.toast('添加成功');
        }
        this.cancel();
        this.$emit('done');
        store.commit('set_user_info_group',this.data.group_id);
      });
    },
    blurLink(){
      if(!this.data.url || this.data.url === this.oldLink){
        return false;
      }
      this.getWebInfo();
      this.oldLink = this.data.url;
    }
  },
  created() {

  }
}
</script>

<style scoped>

</style>